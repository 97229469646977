import { v1 as uuid } from 'uuid';
import { useLocation, useNavigate } from 'react-router-dom';
import Papa, { ParseResult } from 'papaparse';
import {
  useCallback, useMemo, useState,
} from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import styles from './userRegisterFromCsvScreenPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import Breadcrumb from '../../components/Breadcrumb';
import BaseTable from '../../components/BaseTable';
import { useRegisterUsersFromCsvApi } from '../../hooks/api/user.hook';
import routes from '../../utils/routes';
import AlertModal, {
  alertModalInitialState,
  CloseAlertModal,
  closeModalInitialState,
} from '../../components/AlertModal';
import { UserFromCSV, UserFromCSVList } from '../../services/http/user.api';
import MappingSelect from '../../components/MappingSelect';
import { FileState, SelectedOption } from './csvImportScreenTypes';
import { useMessageModal } from '../../hooks/modal.hook';
import LoadingOverlay from '../../components/LoadingOverlay';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import Button from '../../components/Button/button';
import MainFrame from '../../components/MainFrame/mainFrame';
import { STATUS_MESSAGES } from '../../utils/messages';
import Validator from '../../utils/validators';

/**
 * CSVページからの管理情報取り込みマッピング設定ページ
 */
function Component() {
  usePageTitle('管理情報取り込みマッピング設定');
  const navigate = useNavigate();
  const { state } = useLocation();
  const { file } = state as FileState;
  const { request: createLog } = useCreateLogApi();

  // states
  const [parsedCsvData, setParsedCsvData] = useState<object[] | null>(null);
  const [objKeys, setObjKeys] = useState<string[]>([]);
  const [usersToRegister, setUsersToRegister] = useState<any[]>([]);
  const [usersToDisplay, setUsersToDisplay] = useState<any[]>([]);
  const [userNameSelected, setUserNameSelected] = useState<string>('');
  const [emailSelected, setEmailSelected] = useState<string>('');
  const [adminFlgSelected, setAdminFlgSelected] = useState<string>('');
  const [passwordSelected, setPasswordSelected] = useState<string>('');
  const [changedObject, setChangedObject] = useState<SelectedOption>({ changedKey: '', changedOption: '' });
  const [alertModal, setAlertModal] = useState(alertModalInitialState);
  const [closeAlertModal, setCloseAlertModal] = useState(closeModalInitialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const openMessageModal = useMessageModal();

  // Apis
  const registerUsersFromCsv = useRegisterUsersFromCsvApi();

  // methods
  const onCancel = useCallback(() => {
    setUserNameSelected('');
    setEmailSelected('');
    setAdminFlgSelected('');
    setPasswordSelected('');
    navigate(routes.userListScreen);
  }, [navigate]);

  const alertModalFunction = useCallback((text: string, onClose: () => void) => {
    setCloseAlertModal({
      ...closeAlertModal,
      open: true,
      text,
      onCancel: () => { onClose(); },
    });
  }, [closeAlertModal]);

  const runValidation = (
    validatorFn: () => void,
    fieldLabel: string,
    lineNumber: number,
  ): boolean => {
    try {
      validatorFn();
      return true;
    } catch (e) {
      const errorMsg = `${lineNumber}行目：「${fieldLabel}」が不正です。\nCSVファイルを修正してから再度取込んでください。`;
      openMessageModal(errorMsg);
      return false;
    }
  };

  const validateCsvUsers = (rawUsers: (UserFromCSV & { no: number })[]): boolean => rawUsers.every((user) => {
    const requiredFields = [
      { key: 'userName', label: 'ユーザー名', value: user.userName },
      { key: 'email', label: 'メールアドレス', value: user.email },
      { key: 'password', label: 'パスワード', value: user.password },
    ];

    const requiredValidations = requiredFields.every(({ label, value }) => runValidation(() => {
      if (!value) {
        throw new Error();
      }
    }, label, user.no));

    const isPasswordValid = runValidation(() => {
      if (user.password) {
        Validator.validPasswordLength(user.password);
        Validator.validPasswordTypeChars(user.password);
        Validator.validPasswordChars(user.password);
      }
    }, 'パスワード', user.no);

    const isEmailValid = runValidation(() => {
      if (user.email) {
        Validator.validateEmail(user.email);
      }
    }, 'メールアドレス', user.no);

    const isUserNameValid = runValidation(() => {
      if (user.userName) {
        Validator.validUserName(user.userName);
      }
    }, 'ユーザー名', user.no);

    return requiredValidations && isUserNameValid && isEmailValid && isPasswordValid;
  });

  const onSave = useCallback(() => {
    if (usersToRegister.length > 500) {
      openMessageModal('1回のユーザー登録は500件以内にしてください。');
      return;
    }
    if (userNameSelected === '' || emailSelected === '' || passwordSelected === '') {
      alertModalFunction('ユーザー名、メールアドレス、 初期パスワードのいずれかが選択されていません。', () => setCloseAlertModal(closeModalInitialState));
      return;
    }

    const mapUser = (user: Record<string, any>): UserFromCSV => ({
      userName: user[userNameSelected],
      email: user[emailSelected],
      adminFlg: user[adminFlgSelected] ? user[adminFlgSelected] : null,
      password: user[passwordSelected],
    });

    const registerForm: UserFromCSV[] = usersToRegister.map(mapUser);

    const form: UserFromCSVList = {
      file: [...registerForm,
      ],
    };

    const validateUserForm = usersToRegister.map((user) => ({
      ...mapUser(user),
      no: user.No,
    }));

    if (!validateCsvUsers(validateUserForm)) {
      return;
    }

    setAlertModal({
      open: true,
      text: '保存してよろしいですか？',
      onConfirm: async () => {
        setAlertModal({ ...alertModal, open: false });
        setIsLoading(true);
        const fetch = async (param: UserFromCSVList) => registerUsersFromCsv.store(param);
        try {
          createLog(LogFormName.UserRegisterFromCsvScreen, LogControlName.Create);
          // Removed await from fetch function, and added a setTimeout to simulate the loading for 1 second
          // This was requested by the client because this request could take a long time sending confirmation email to the users
          // This is a temporary solution
          fetch(form);
          new Promise((resolve) => {
            setTimeout(resolve, 1000);
          }).then(() => {
            setIsLoading(false);
            alertModalFunction('登録を受け付けました。\n反映まで時間がかかる場合があります。', () => onCancel());
          });
        } catch (e) {
          setIsLoading(false);
          openMessageModal((e as Error)?.message);
        }
      },
      onCancel: () => {
        setAlertModal({
          ...alertModal, open: false,
        });
      },
    });
  }, [userNameSelected, emailSelected, passwordSelected, usersToRegister, alertModalFunction, adminFlgSelected, alertModal, registerUsersFromCsv, openMessageModal, onCancel]);

  const onRowNumberSelected = useCallback((targetRow: string) => {
    const TargetRowMinus1 = Number(targetRow) - 1;
    const sliced100docs = parsedCsvData?.slice(TargetRowMinus1, TargetRowMinus1 + 100) ?? [];
    const restOfDocs = parsedCsvData?.slice(TargetRowMinus1) ?? [];
    setUsersToDisplay(sliced100docs);
    setUsersToRegister(restOfDocs);
  }, [parsedCsvData]);

  const makeRowSelectOptions = useCallback((objLength: number) => {
    const options = [];
    for (let i = 0; i < objLength; i += 1) {
      options.push({
        value: i + 1,
        label: i + 1,
      });
    }
    return options;
  }, []);

  const getDynamicColumn = useCallback((array: object[]) => Object.keys(array[0]).map(((key) => {
    const columnWidth = key === Object.keys(array[0])[0] ? 80 : 150;
    return {
      field: key, headerName: key, cellClass: 'textFormat', width: columnWidth,
    };
  })), []);

  const generateColumns = useCallback((data: object[]) => {
    const lastColumn = {
      headerName: '', field: '', flex: 1, suppressColumnsToolPanel: true, resizable: true,
    };
    return [...getDynamicColumn(data), lastColumn];
  }, [getDynamicColumn]);

  const clearColumns = useCallback((data: object[]) => data
    .map((obj) => Object.entries(obj)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value.trim() }), {})), []);

  const parseFile = useCallback((fileToParse: string, agGridParams: GridReadyEvent) => {
    Papa.parse(fileToParse, {
      header: true,
      skipEmptyLines: 'greedy',
      complete: (results: ParseResult<object>) => {
        const { data } = results;
        const clearColumnsData = clearColumns(data);
        const dataWithNo = clearColumnsData.map((obj, index) => ({ No: index + 1, ...obj }));
        const allColumns = generateColumns(dataWithNo);
        agGridParams.api.setColumnDefs(allColumns);
        setParsedCsvData(dataWithNo);
        setObjKeys(Object.keys(data[0]));
        setUsersToRegister(dataWithNo);
        setUsersToDisplay(dataWithNo.slice(0, 100));
      },
    });
  }, [clearColumns, generateColumns]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    parseFile(file, params);
  }, [file, parseFile]);

  const resetPreviousSelectedOption = useCallback((prevSelectedOption: string) => {
    if (prevSelectedOption === 'userName') setUserNameSelected('');
    if (prevSelectedOption === 'email') setEmailSelected('');
    if (prevSelectedOption === 'password') setPasswordSelected('');
    if (prevSelectedOption === 'adminFlg') setAdminFlgSelected('');
  }, []);

  const onOptionSelected = useCallback((value: string, columnName: string, previousSelectedOption: string) => {
    const previousSelectedOptionFormatted = previousSelectedOption === 'ignore' ? previousSelectedOption : JSON.parse(previousSelectedOption).value;

    const valueFormatted = JSON.parse(value).value;
    setChangedObject({ changedOption: value, changedKey: columnName });

    if (valueFormatted === 'userName') {
      resetPreviousSelectedOption(previousSelectedOptionFormatted);
      setUserNameSelected(columnName);
      return;
    }
    if (valueFormatted === 'email') {
      resetPreviousSelectedOption(previousSelectedOptionFormatted);
      setEmailSelected(columnName);
      return;
    }
    if (valueFormatted === 'adminFlg') {
      resetPreviousSelectedOption(previousSelectedOptionFormatted);
      setAdminFlgSelected(columnName);
      return;
    }
    if (valueFormatted === 'password') {
      resetPreviousSelectedOption(previousSelectedOptionFormatted);
      setPasswordSelected(columnName);
      return;
    }
    if (valueFormatted === 'ignore') {
      if (userNameSelected === columnName) {
        setUserNameSelected('');
        return;
      }
      if (emailSelected === columnName) {
        setEmailSelected('');
        return;
      }
      if (adminFlgSelected === columnName) {
        setAdminFlgSelected('');
        return;
      }
      if (passwordSelected === columnName) {
        setPasswordSelected('');
      }
    }
  }, [userNameSelected, emailSelected, passwordSelected, adminFlgSelected, resetPreviousSelectedOption]);

  // Const
  const columnWidth = 150;
  // const defColumnsDefs = { width: columnWidth };
  const tableMinWidth = `${objKeys.length * 150 + 150}px`;

  // Memos
  const selectOptions = useMemo(() => [
    { value: 'ignore', label: '無視する ' },
    { value: 'userName', label: 'ユーザー名' },
    { value: 'email', label: 'メールアドレス' },
    { value: 'password', label: '初期パスワード' },
    { value: 'adminFlg', label: 'ユーザー種別' },
  ], []);

  const rowSelectOptions = useMemo(() => {
    if (parsedCsvData == null) return null;

    const options = makeRowSelectOptions(parsedCsvData.length);
    return options.map((option) => (
      <option key={uuid()} value={option.value}>{option.label}</option>
    ));
  }, [makeRowSelectOptions, parsedCsvData]);

  useEffectOnce(() => {
    createLog(LogFormName.UserRegisterFromCsvScreen, LogControlName.Show);
  });

  return (
    <MainFrame body={(
      <div className={styles.mainFrame}>
        <Breadcrumb crumbs={[{ label: document.title }]} />
        <div className={[styles.mainFrameHeader, mainStyles['pl-3']].join(' ')}>
          管理情報取り込みマッピング設定
        </div>
        <div className={styles.bodyContentTop}>
          <div className="topSection">
            <div className={styles.text}>
              {' 取り込み開始行 '}
              <select className={[mainStyles.select, mainStyles['mx-10px']].join(' ')} style={{ width: '90px' }} onChange={(e) => onRowNumberSelected(e.target.value)}>
                {rowSelectOptions}
              </select>
              {' 行目 '}
            </div>
          </div>
          <div className={[styles.text, styles.ml70px, mainStyles['mt-15px']].join(' ')}>
            取り込みデータ
            {'　'}
            （最初の100行）
          </div>
        </div>
        <div className={styles.mainFrameBody}>
          <div className={styles.bodyContent}>

            <div className={styles.bodyContentMain}>
              <div className={styles.bodyContentMainTable}>
                <div className={mainStyles['mt-15px']} style={{ height: '100%' }}>
                  <BaseTable
                    noRowsText={parsedCsvData == null ? STATUS_MESSAGES.LOADING_DATA : STATUS_MESSAGES.NO_DATA}
                    formName="userRegisterFromCsvTable"
                    rowData={usersToDisplay}
                    // defaultColDef={defColumnsDefs}
                    onGridReady={onGridReady}
                    minWidthProp={tableMinWidth}
                  />
                </div>
              </div>
              <div className={styles.bodyContentMainBottom}>
                <div className={[styles.text, styles.ml70px, mainStyles['mt-15px'], styles.textBold].join(' ')}>
                  マッピング設定
                </div>

                <div className={[mainStyles['d-flex'], mainStyles['mt-20px'], styles.mr80px].join(' ')}>
                  <div className={[styles.text, styles.w70px, mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['justify-content-end']].join(' ')}>
                    <span className={[mainStyles['mr-3'], styles.w70px, mainStyles['d-flex'], mainStyles['justify-content-end']].join(' ')}>
                      CSV
                    </span>
                  </div>
                  <div className={[mainStyles['flex-1'], mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                    <div className={[styles.divBox, styles.borderLeft].join(' ')} style={{ minWidth: '80px', maxWidth: '80px' }}>No</div>
                    {objKeys.map((key) => (
                      <div key={key} className={[styles.divBox].join(' ')} style={{ minWidth: `${columnWidth}px`, maxWidth: `${columnWidth}px` }}>{key}</div>
                    ))}
                  </div>
                </div>
                <div className={mainStyles['d-flex']}>
                  <div className={[styles.text, styles.w70px, mainStyles['d-flex'], mainStyles['justify-content-end']].join(' ')}>
                    <span className={[styles.downArrow, mainStyles['mr-4'], styles.w70px].join(' ')}>
                      ↓
                    </span>
                  </div>
                </div>
                <div className={[mainStyles['d-flex'], mainStyles['mt-10'], styles.mr80px].join(' ')}>
                  <div className={[styles.text, styles.w70px, mainStyles['d-flex'], mainStyles['justify-content-end']].join(' ')}>
                    <span className={[mainStyles['mr-3'], styles.w70px, mainStyles['d-flex'], mainStyles['justify-content-end']].join(' ')}>
                      登録項目
                    </span>
                  </div>
                  <div className={[mainStyles['flex-1'], mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                    <div className={[styles.divBox, styles.borderLeft].join(' ')} style={{ minWidth: '80px', maxWidth: '80px', backgroundColor: '#f2f2f2' }} />
                    {objKeys.map((key) => (
                      <MappingSelect
                        key={key}
                        objKey={key}
                        columnWidth={columnWidth}
                        onOptionSelected={onOptionSelected}
                        optionWasChanged={changedObject}
                        selectOptions={selectOptions}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <AlertModal open={alertModal.open} text={alertModal.text} onConfirm={alertModal.onConfirm} onCancel={alertModal.onCancel} textCenter />
            <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} />
          </div>

        </div>
        <div className={styles.bodyFooter}>
          <Button color="lightGray" size="smaller" onClick={onCancel}>キャンセル</Button>
          <Button className={`${mainStyles['ml-1']}`} size="smaller" onClick={onSave}>
            保存
          </Button>
        </div>
        {isLoading && <LoadingOverlay />}
      </div>
    )}
    />
  );
}

export default Component;
