import { AgGridReact } from 'ag-grid-react';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ColDef, ICellRendererParams, RowClickedEvent,
  ValueGetterParams,
} from 'ag-grid-community';
import mainStyles from '../main.module.css';
import styles from './remoteScanTab.module.css';
import BaseTable from '../../components/BaseTable';
import { DocumentDetail } from '../../services/http/documents.api';
import AGUtils from '../../utils/ag-grid.utils';
import { useDocumentDownload, useDocumentGetDetail } from '../../hooks/api/document.hook';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import { PdfPreviewModal } from '../../components/PdfPreviewModal';
import { useCreateDownloadFileLogApi } from '../../hooks/api/log.hook';
import CartDocDetailModalFrame from '../../components/CartDocDetailModal/cartDocDetailModalFrame';
import Button from '../../components/Button/button';
import RemoteScanModal from './remoteScanModal';
import { useReservationCartDelete, useReservationCartDeleteAll, useReservationCartSearch } from '../../hooks/api/reservationCart.hook';
import { CartDocument, RequestType } from '../../services/http/reservationCart.api';
import routes from '../../utils/routes';
import { useAuthContext } from '../../store/auth.store';
import ControlCode from '../../utils/controlCode';
import { LogFormName } from '../../utils/log.utils';
import AlertModal from '../../components/AlertModal';
import { modalInitialState } from '../category-list/categoryListPage';
import Spinner from '../../components/Spinner';
import { useGetAllTagsApi } from '../../hooks/api/tag.hook';
import { STATUS_MESSAGES } from '../../utils/messages';

/**
 * 入庫依頼のプロップスインターフェース
 */
interface TableBtnCellRenderProps {
  /** 削除ボタンクリックハンドラー */
  onDeleteClick: (documentWarehousing: CartDocument) => void;
  /** 入庫依頼データ */
  data: CartDocument;
}

/**
 * テーブルセルレンダラーコンポーネント
 */
function TableBtnCellRender({ data, onDeleteClick }: TableBtnCellRenderProps) {
  return (
    <div>
      <button type="button" className={[mainStyles['table-button'], mainStyles['btn-gray']].join(' ')} onClick={() => onDeleteClick(data)}>削除</button>
    </div>
  );
}

export interface Props {
  total: number | null,
  fetchReservationCartGetCount: () => Promise<void>,
}

function Component(props: Props) {
  const { total, fetchReservationCartGetCount } = props;
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const { hasPermission } = useAuthContext();
  const [suppressModal, setSuppressModal] = useState(false);
  const [detailedDocument, setDetailedDocument] = useState<DocumentDetail | null>(null);
  const [tabDocuments, setTabDocuments] = useState<CartDocument[] | null>([]);
  const [selectedDocument, setSelectedDocument] = useState<CartDocument | null>(null);
  const [pdfForModal, setPdfForModal] = useState<File | null>(null);
  const [showRemoteScanModal, setShowRemoteScanModal] = useState(false);
  const [modal, setModal] = useState(modalInitialState);
  const [noRowsText, setNoRowsText] = useState('　');
  const [isLoading, setIsLoading] = useState(false);

  const { allTags, reload: getAllTags, loadingAllTags } = useGetAllTagsApi();
  const { request: reservationCartSearchRequest, loading: loadingReservationCartSearch } = useReservationCartSearch();
  const { request: reservationCartDeleteAllRequest, loading: loadingReservationCartDeleteAll } = useReservationCartDeleteAll();
  const { request: reservationCartDeleteRequest, loading: loadingReservationCartDelete } = useReservationCartDelete();
  const { request: documentDownload, loading: documentDownloadLoading } = useDocumentDownload();
  const { request: createDownloadLog } = useCreateDownloadFileLogApi();
  const openMessageModal = useMessageModal();
  const documentGetDetail = useDocumentGetDetail();

  const isTableLoading = isLoading || loadingReservationCartSearch || documentDownloadLoading || loadingReservationCartDeleteAll || loadingReservationCartDelete;

  const fetchDocuments = useCallback(async () => {
    try {
      const res = await reservationCartSearchRequest({
        requestType: RequestType.REMOTE_SCAN,
      });
      if (res.documentList.length === 0) setNoRowsText(STATUS_MESSAGES.NO_DATA);
      setTabDocuments(res.documentList);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, [reservationCartSearchRequest, openMessageModal]);

  const onPdfPreviewRequest = useCallback((doc: CartDocument) => {
    setSuppressModal(true);
    if (doc) {
      try {
        documentGetDetail.request({ id: doc.documentId }).then(setDetailedDocument);
      } catch (e) {
        openMessageModal((e as ApiError)?.message);
      }
    }
  }, [documentGetDetail, openMessageModal]);

  const onSelectDocument = (document: CartDocument) => {
    if (suppressModal) {
      setSelectedDocument(null);
      setTimeout(() => {
        setSuppressModal(false);
      }, 200);
    } else if (document) setSelectedDocument(document);
  };

  const onTableRowClicked = (e: RowClickedEvent) => {
    onSelectDocument(e.data);
  };

  const onDeleteDocument = async (document: CartDocument) => {
    setSuppressModal(true);
    setIsLoading(true);
    try {
      await reservationCartDeleteRequest({
        documentId: document.documentId,
      });
      await fetchReservationCartGetCount();
      await fetchDocuments();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      openMessageModal((e as ApiError)?.message);
    }
  };

  // Memos
  const colsDefs = useMemo((): ColDef<CartDocument>[] => {
    const cols: ColDef<CartDocument>[] = [
      {
        field: 'buttons',
        headerName: '',
        resizable: false,
        pinned: 'left',
        suppressColumnsToolPanel: true,
        width: 80,
        cellRenderer: TableBtnCellRender,
        cellRendererParams: {
          onDeleteClick: onDeleteDocument,
        },
      },
      AGUtils.colDefault('ccControlNumber', '文書ID'),
      {
        headerName: '文書名',
        resizable: true,
        sortable: true,
        field: 'documentName',
        width: 250,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (params: ICellRendererParams) => (
          <div
            style={{ alignContent: 'center', display: 'flex' }}
          >
            {params.node.data.documentFileName
              ? (
                <img
                  src="/images/Pdf-icon.svg"
                  alt="pdf"
                  onClick={() => onPdfPreviewRequest(params.node.data)}
                />
              )
              : (
                <img
                  src="/images/Pdf-icon-semitransparent.svg"
                  alt="pdf"
                  onClick={() => setSuppressModal(true)}
                />
              )}
            <span className={[mainStyles['ml-2'], mainStyles['text-overflow'], 'highlightTarget'].join(' ')}>
              {params.value}
            </span>
            {params.node.data.requiredTagInputFlg !== 1 && (
              <div style={{ marginLeft: 'auto' }}>
                <img src="/images/Icon-awesome-exclamation.svg" alt="exclamation" style={{ height: '12px', marginRight: '5px' }} />
              </div>
            )}
          </div>
        ),
      },
      AGUtils.colDefault('documentTypeName', '文書種類', 130),
      AGUtils.colDefault('documentFileName', 'ファイル名', 170),
      AGUtils.colFileSize('documentFileSize', 'サイズ'),
      AGUtils.colUsername('registUser', '登録者'),
      AGUtils.colDate('registDate', '登録日時'),
      AGUtils.colUsername('updateUser', '更新者'),
      AGUtils.colDate('updateDate', '更新日時'),
      AGUtils.colDefault('warehouseStatus', 'ステータス', 180),
      AGUtils.colDefault('itemCode', '保管品バーコード'),
      AGUtils.colDefault('barcodePrinting', 'バーコード印字欄'),
    ];

    const tags = new Set<string>(allTags.map((tag) => tag.tagLabel));
    const unique = Array.from(tags);

    unique.forEach((tag) => {
      const apiTag = allTags.find(({ tagLabel }) => (tagLabel === tag)) ?? null;
      cols.push({
        field: `${tag}`,
        headerName: tag,
        resizable: true,
        sortable: true,
        width: 160,
        valueGetter: (valueGetterParams) => {
          const { data } = valueGetterParams;
          const tagValueGetter = AGUtils.getTagValueGetter(tag, apiTag);
          const tagList = data?.tagList?.map(({ tagLabel, tagValue }) => ({
            label: tagLabel,
            value: tagValue,
          }));
          const parsedData = { tagList };
          return tagValueGetter({ ...valueGetterParams, data: parsedData } as ValueGetterParams);
        },
      });
    });

    return cols;
  }, [allTags]);

  const closeModal = () => {
    setTimeout(() => {
      setSelectedDocument(null);
    }, 100);
  };

  const deleteAll = async () => {
    setModal({ ...modal, open: false });
    setIsLoading(true);
    try {
      await reservationCartDeleteAllRequest({
        requestType: RequestType.REMOTE_SCAN,
      });
      await fetchReservationCartGetCount();
      await fetchDocuments();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      openMessageModal((e as ApiError)?.message);
    }
  };

  const handleClickDeleteAll = async () => {
    setModal({
      open: true,
      text: '表示中の依頼リストをクリアします。\nよろしいですか？',
      onConfirm: deleteAll,
      onCancel: () => setModal({ ...modal, open: false }),
    });
  };

  const fetchData = useCallback(() => {
    getAllTags();
    fetchReservationCartGetCount();
    fetchDocuments();
  }, [getAllTags, fetchReservationCartGetCount, fetchDocuments]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (detailedDocument) {
      if (detailedDocument.documents.length === 0) {
        openMessageModal('PDFファイルが見つかりませんでした。');
        return;
      }
      const current = detailedDocument.documents.sort((a, b) => b.versionNo - a.versionNo)[0];
      documentDownload({ documentDataId: current.dataId, fileName: current.fileName })
        .then((file) => {
          createDownloadLog(current.dataId, 1);
          setPdfForModal(file);
        })
        .catch(() => openMessageModal('PDFファイルが見つかりませんでした。'));
    }
  }, [detailedDocument, documentDownload, openMessageModal]);

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.tableContainer}>
          {isTableLoading && (
            <div className={styles.loadingContainer}>
              <div className={styles.loading}>
                <Spinner />
              </div>
            </div>
          )}
          <BaseTable<CartDocument>
            tableRef={gridRef}
            formName="remoteScanScreenCartTable"
            waitToLoad={loadingAllTags}
            columnDefs={colsDefs}
            rowData={tabDocuments}
            createLogOnDownloadTableData={LogFormName.RemoteScanScreen}
            suppressRowClickSelection
            onRowClicked={(e) => onTableRowClicked(e)}
            noRowsText={noRowsText}
            useColPin
            suppressCellFocus
            sideBar
          />
          {selectedDocument && (
            <CartDocDetailModalFrame
              document={selectedDocument}
              onClose={closeModal}
            />
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <Button color="lighterGray" size="smaller" onClick={() => navigate(routes.main)}>キャンセル</Button>
        <div className={styles.confirmContainer}>
          <span>
            依頼対象：
            {(total ?? 0).toLocaleString()}
            件
          </span>
          <Button color="lighterGray" size="smaller" onClick={handleClickDeleteAll} disabled={isTableLoading || !tabDocuments?.length}>
            リストを空にする
          </Button>
          <Button className={styles.confirmButton} size="smaller" onClick={() => setShowRemoteScanModal(true)} disabled={!hasPermission(ControlCode.Scan) || isTableLoading || !tabDocuments?.length}>このリストで依頼する</Button>
        </div>
      </div>
      {(detailedDocument && pdfForModal) && (
        <PdfPreviewModal
          open
          onClose={() => setPdfForModal(null)}
          pdfFile={pdfForModal}
          fileName={detailedDocument?.documents[0].fileName}
          fileId={detailedDocument?.documents[0].dataId}
          showOcrSearchPreview={detailedDocument?.documents[0].documentFileOcrFlg}
          documentVersionNo={detailedDocument?.documents[0].versionNo}
          documentId={detailedDocument?.id}
        />
      )}
      {tabDocuments != null && showRemoteScanModal && (
        <RemoteScanModal
          documentList={tabDocuments.map(({ documentId }) => ({ documentId }))}
          onDocumentRequestDone={fetchData}
          onCancel={() => setShowRemoteScanModal(false)}
          onSuccess={() => setShowRemoteScanModal(false)}
          onError={() => {
            fetchData();
            setShowRemoteScanModal(false);
          }}
        />
      )}
      <AlertModal open={modal.open} text={modal.text} onConfirm={modal.onConfirm} onCancel={modal.onCancel} />
    </div>
  );
}

export default Component;
