import React, {
  useCallback, useEffect, useState,
} from 'react';
import styles from './searchInputTop.module.css';
import OptionsIcon from '../../assets/images/Icon-ionic-md-options.svg';
import Button from '../Button/button';
import SearchInputTopFilterModal, { LastSearch } from './SearchInputTopFilterModal';
import { DocumentSearchDetailForm } from '../../services/http/documents.api';
import BaseText from '../BaseText/BaseText';
import { useAuthContext } from '../../store/auth.store';
import { useMessageModal } from '../../hooks/modal.hook';
import CustomSwitch from '../CustomSwitch/CustomSwitch';

export enum ContractType {
  ELECTRONIC = 'ELECTRONIC',
  PAPER = 'PAPER',
  ALL = 'ALL',
}

export enum WarehouseStatusType {
  NOT_STORED = 'NOT_STORED',
  STORED = 'STORED',
  ALL = 'ALL',
}

const warehouseStatusText: Record<WarehouseStatusType, string> = {
  NOT_STORED: '：未入庫',
  STORED: '：入庫済',
  ALL: '',
};

const contractTypeText: Record<ContractType, string> = {
  ELECTRONIC: '：電子契約',
  PAPER: '：書面契約',
  ALL: '',
};

interface Props {
  text: string;
  isSearching: boolean;
  contractType: ContractType;
  warehouseStatus: WarehouseStatusType;
  onAdvancedSearch: (advancedSearch: DocumentSearchDetailForm) => void;
  onSearch: (text: string) => void;
  onTextChange: (text: string) => void;
  onChangeContractType: (contractType: ContractType) => void;
  onChangeWarehouseStatus: (warehouseStatus: WarehouseStatusType) => void;
  setIsOcrSearchEnabled?: (isOcrSearchEnabled: boolean) => void;
  isOcrSearchEnabled?: boolean;
  setLastAdvancedSearchForm?: (lastAdvancedSearchForm: LastSearch) => void;
  lastAdvancedSearchForm?: LastSearch | null;
  showOcrSwitch?: boolean;
  onChangeOcrStatus?: () => void;
}

function SearchInputTop({
  text,
  isSearching,
  contractType,
  warehouseStatus,
  onAdvancedSearch,
  onSearch,
  onTextChange,
  onChangeContractType,
  onChangeWarehouseStatus,
  setIsOcrSearchEnabled,
  isOcrSearchEnabled,
  setLastAdvancedSearchForm,
  lastAdvancedSearchForm,
  showOcrSwitch = false,
  onChangeOcrStatus,
}: Props) {
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [showAdvancedFilterMenu, setShowAdvancedFilterMenu] = useState(false);

  const { user } = useAuthContext();

  const openMessageModal = useMessageModal();

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = (e.target as HTMLInputElement);
    if (e.key === 'Enter') {
      if (isOcrSearchEnabled && value.length <= 1) return;
      onSearch(text);
      Promise.resolve().then(() => {
        onTextChange(value);
      });
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = (e.target as HTMLInputElement);
    if (e.key === 'Enter') {
      if (isOcrSearchEnabled && value.length <= 1) openMessageModal('検索したいキーワードは2文字以上を指定してください。');
      (e.target as HTMLInputElement).blur();
    }
  };

  const toggleContractType = useCallback(() => {
    if (contractType === ContractType.ELECTRONIC) {
      onChangeContractType(ContractType.PAPER);
    } else if (contractType === ContractType.PAPER) {
      onChangeContractType(ContractType.ALL);
    } else {
      onChangeContractType(ContractType.ELECTRONIC);
    }
  }, [contractType, onChangeContractType]);

  const toggleWarehouseStatus = useCallback(() => {
    if (warehouseStatus === WarehouseStatusType.NOT_STORED) {
      onChangeWarehouseStatus(WarehouseStatusType.STORED);
    } else if (warehouseStatus === WarehouseStatusType.STORED) {
      onChangeWarehouseStatus(WarehouseStatusType.ALL);
    } else {
      onChangeWarehouseStatus(WarehouseStatusType.NOT_STORED);
    }
  }, [warehouseStatus, onChangeWarehouseStatus]);

  return (
    <div className={styles.container}>
      <div className={styles.searchBox}>
        <img className={styles.searchImg} alt="" src="images/search.svg" />
      </div>
      <div style={{ position: 'relative', flex: 1 }}>
        <input
          className={styles.input}
          value={text}
          onChange={(e) => onTextChange(e.target.value)}
          onKeyDown={onKeyPress}
          onFocus={() => setShowFilterMenu(true)}
          onBlur={() => setShowFilterMenu(false)}
          onKeyUp={handleKeyUp}
        />
        {
          (showFilterMenu && isSearching)
          && (
          <div className={styles['filter-dropdown']}>
            <Button
              className={styles['filter-button']}
              size="smaller"
              outline
              color={warehouseStatus === WarehouseStatusType.ALL ? 'lightGray' : 'orange'}
              background={warehouseStatus === WarehouseStatusType.ALL ? 'white' : 'lighterOrange'}
              onClick={toggleWarehouseStatus}
              onMouseDown={(e) => e.preventDefault()}
            >
              ステータス
              {warehouseStatusText[warehouseStatus]}
            </Button>
            <Button
              className={styles['filter-button']}
              size="smaller"
              outline
              color={contractType === 'ALL' ? 'lightGray' : 'orange'}
              background={contractType === 'ALL' ? 'white' : 'lighterOrange'}
              onClick={toggleContractType}
              onMouseDown={(e) => e.preventDefault()}
            >
              契約種類
              {contractTypeText[contractType]}
            </Button>
          </div>
          )
        }
      </div>
      <div className={styles['search-options-container']}>
        { (!!user?.hasOcrSupport && showOcrSwitch) && (
          <>
            <BaseText size="sm" color="#8c8282" style={{ marginBottom: 1, marginRight: 7 }}>全文検索</BaseText>
            <CustomSwitch
              checked={isOcrSearchEnabled}
              onChange={(event) => {
                const newValue = event.target.checked;
                localStorage.setItem('is_ocr_search_enabled', JSON.stringify(newValue));
                if (setIsOcrSearchEnabled) setIsOcrSearchEnabled(newValue);
                if (onChangeOcrStatus) onChangeOcrStatus();
              }}
            />
            <div />
            <div className={styles['vertical-divider']} />
          </>
        )}
        <img
          className={`${styles.filterButton}`}
          alt=""
          width="26"
          src={OptionsIcon}
          onClick={() => setShowAdvancedFilterMenu(!showAdvancedFilterMenu)}
        />
        {showAdvancedFilterMenu && (
          <SearchInputTopFilterModal
            open={showAdvancedFilterMenu}
            onAdvancedSearch={onAdvancedSearch}
            onClose={() => setShowAdvancedFilterMenu(false)}
            simpleSearchText={text}
            setLastAdvancedSearchForm={setLastAdvancedSearchForm}
            lastAdvancedSearchForm={lastAdvancedSearchForm}
            isOcrSearchEnabled={isOcrSearchEnabled}
          />
        )}
      </div>
    </div>
  );
}

export default SearchInputTop;
