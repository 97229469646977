import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './nonDocumentPermissionScreenPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import Breadcrumb from '../../components/Breadcrumb';
import routes from '../../utils/routes';
import { useUserGetDetailApi } from '../../hooks/api/user.hook';
import useGetUserAuthorityApi, { useRegisterUserAuthorityApi } from '../../hooks/api/userAuthority.hook';
import useGetUserAuthorityPresetApi from '../../hooks/api/userAuthorityPreset.hook';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import { UserAuthorityPreset, UserAuthorityPresetControl } from '../../services/http/userAuthorityPreset';
import {
  presetListControlItems,
  renderControlList,
} from '../permissionTemplateCreationPage/permissionTemplateCreationPage';
import AlertModal from '../../components/AlertModal';
import { ManagementType, useAuthContext } from '../../store/auth.store';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import { UserSearchForm } from '../../services/http/user.api';
import ControlCode from '../../utils/controlCode';

/**
 * 各種依頼権限確認・更新（一般）ページ
 */
function Component() {
  usePageTitle('各種依頼権限確認・更新（一般）');
  const [searchParams] = useSearchParams();
  const [userId, setUserId] = useState('');
  const [templateData, setTemplateData] = useState<UserAuthorityPreset | null>(null);
  const [selectedControlList, setSelectedControlList] = useState<UserAuthorityPresetControl[]>([]);
  const [modalInfo, setModalInfo] = useState<{ open: boolean, title: string, handleComfirm?:() => void }>({ open: false, title: '各種依頼権限を更新してよろしいですか？' });
  const [userInfoWidth, setUserInfoWidth] = useState(0);
  const navigator = useNavigate();
  const openMessageModal = useMessageModal();
  const { hasPermission } = useAuthContext();

  const userInfoRef = useRef<HTMLDivElement>(null);

  const { request: userGetDetailApiRequest, data: userDetail } = useUserGetDetailApi();
  const { request: userGetUserAuthorityPresetApiRequest, data: userAuthorityPresetData } = useGetUserAuthorityPresetApi();
  const { request: userGetAuthorityRequest } = useGetUserAuthorityApi();
  const { request: userRegisterAuthorityRequest } = useRegisterUserAuthorityApi();

  const { state } = useLocation();
  const { ...searchForm } = state as UserSearchForm;

  useEffect(() => {
    const id = searchParams.get('userId');
    if (id == null) {
      return;
    }
    setUserId(id);

    userGetDetailApiRequest({ id });
  }, [searchParams, userGetDetailApiRequest]);

  useEffect(() => {
    userGetUserAuthorityPresetApiRequest();
  }, [userGetUserAuthorityPresetApiRequest]);

  useEffect(() => {
    if (userId === '') {
      return;
    }
    userGetAuthorityRequest(userId).then((e) => {
      setSelectedControlList(e);
    });
  }, [userGetAuthorityRequest, userId]);

  useEffect(() => {
    const f = () => {
      const width = userInfoRef.current?.clientWidth;
      if (width == null) {
        return;
      }
      setUserInfoWidth(width);
    };
    window.addEventListener('resize', f);

    return () => {
      window.removeEventListener('resize', f);
    };
  }, []);

  const descWidth = userInfoRef.current?.clientWidth || userInfoWidth;

  const handleTemplateDataOptionSelected = useCallback((value: UserAuthorityPreset | null) => {
    setTemplateData(value);
  }, []);

  const templateDatasSearchOptions = useMemo(() => [{ text: ' ', value: null }, ...userAuthorityPresetData.map((u) => ({
    text: u.presetName,
    value: u,
  }))], [userAuthorityPresetData]);

  const handleCheckAllControlList = () => {
    const all = presetListControlItems.map((d) => d.children).flat().map((d) => d?.id ?? '')
      .filter((controlCode) => hasPermission(controlCode as ControlCode))
      .map((d) => ({ controlCode: d }));
    setSelectedControlList(all);
  };

  const handleCancelAllControlList = () => {
    setSelectedControlList([]);
  };

  const handleReflect = () => {
    setSelectedControlList((f) => {
      if (templateData == null) {
        return f;
      }
      return templateData.controlList;
    });
  };

  const handleRegister = () => {
    setModalInfo((m) => ({ ...m, open: true }));
  };

  const handleCancel = () => {
    navigator(`${routes.userListScreen}`, { state: { temporarySearchForm: searchForm } });
  };

  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.mainframe}>
          <Breadcrumb crumbs={[
            { label: 'ユーザー一覧', route: routes.userListScreen },
            { label: document.title }]}
          />
          <div className={styles['mainframe-body']}>
            <div className={[styles.label, mainStyles['text-bold'], mainStyles['mt-20px']].join(' ')}>
              ユーザー基本情報
            </div>

            <div ref={userInfoRef} className={[mainStyles['ml-20px'], mainStyles['mt-20px'], styles.baseInfo].join(' ')}>
              <div className={styles.baseInfoItem}>
                <div className={styles.label}>
                  ユーザー名
                </div>
                <div className={styles.text}>
                  {userDetail?.userName}
                </div>
              </div>
              <div className={styles.baseInfoItem}>
                <div className={styles.label}>
                  メールアドレス
                </div>
                <div className={styles.text}>
                  {userDetail?.email}
                </div>
              </div>
              <div className={styles.baseInfoItem}>
                <div className={styles.label}>
                  ユーザー種別
                </div>
                <div className={styles.text}>
                  {userDetail?.userAuthenticationType === ManagementType.Admin ? '管理者' : '一般ユーザー'}
                </div>
              </div>
              <div className={styles.baseInfoItem}>
                <div className={styles.label}>
                  ユーザーグループ
                </div>
                <div className={styles.text}>
                  {userDetail?.userGroupList.map((d) => d.userGroupName).join(' ')}
                </div>
              </div>
            </div>
            <div className={[styles.label, mainStyles['text-bold'], mainStyles['mt-20px']].join(' ')}>
              権限設定
            </div>
            <div>
              <div className={[styles.label, mainStyles['mt-20px'], mainStyles['ml-20px']].join(' ')}>
                テンプレートを選択
              </div>
              <div className={[mainStyles['d-flex'], mainStyles['mb-20px'], mainStyles['ml-20px']].join(' ')} style={{ width: `${descWidth}px` }}>
                <AutoCompleteInput<UserAuthorityPreset | null>
                  placeholder="検索"
                  className={[styles.templateSearchInput, mainStyles['w-25'], mainStyles['mr-20px']].join(' ')}
                  posIconClassName={styles.groupSearchInputPosIcon}
                  inputClassName={styles.groupSearchInput}
                  options={templateDatasSearchOptions}
                  onSelect={handleTemplateDataOptionSelected}
                  value={templateData?.presetName ?? ''}
                />
                <button type="button" className={[mainStyles.button, mainStyles['btn-gray'], styles['button-large']].join(' ')} onClick={handleReflect}>テンプレート内容を反映</button>
              </div>
              <div className={[styles.label, mainStyles['ml-20px']].join(' ')}>
                説明
              </div>
              <div className={[mainStyles['d-flex'], mainStyles['ml-20px'], styles.text].join(' ')} style={{ width: `${descWidth}px` }}>
                {templateData?.presetExplanation}
              </div>
              <div className={[mainStyles['mt-20px'], mainStyles['ml-20px']].join(' ')}>
                <button type="button" className={[mainStyles.button, mainStyles['btn-gray'], styles['button-large'], mainStyles['mr-10']].join(' ')} onClick={handleCheckAllControlList}>全ての権限をチェック</button>
                <button type="button" className={[mainStyles.button, mainStyles['btn-gray'], styles['button-large'], mainStyles['mb-20px']].join(' ')} onClick={handleCancelAllControlList}>全てのチェックを外す</button>
              </div>
              <div className={mainStyles['ml-20px']}>
                {renderControlList(selectedControlList, (code: string) => {
                  setSelectedControlList((list) => {
                    if (list.some((l) => l.controlCode === code)) {
                      return list.filter((l) => l.controlCode !== code);
                    }
                    return list.concat({ controlCode: code });
                  });
                }, hasPermission, true)}
              </div>
            </div>
          </div>
          <footer>
            <button type="button" className={[mainStyles.button, mainStyles['btn-gray']].join(' ')} onClick={handleCancel}>キャンセル</button>
            <button type="button" className={[mainStyles.button, mainStyles['btn-primary']].join(' ')} onClick={handleRegister}>保存</button>
          </footer>
          <AlertModal
            open={modalInfo.open}
            text={modalInfo.title}
            confirmText="はい"
            cancelText="いいえ"
            onConfirm={async () => {
              try {
                await userRegisterAuthorityRequest({
                  userId,
                  controlList: selectedControlList,
                });
                navigator(`${routes.userListScreen}`, { state: { temporarySearchForm: searchForm } });
              } catch (e) {
                openMessageModal((e as ApiError)?.message);
              }
              setModalInfo((i) => ({ ...i, open: false }));
            }}
            onCancel={() => {
              setModalInfo((i) => ({ ...i, open: false }));
            }}
          />
        </div>
      )}
    />
  );
}

export default Component;
