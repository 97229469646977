import React, { CSSProperties, ReactNode, useState } from 'react';
import style from './alertModal.module.css';
import Button from '../Button/button';
import ButtonFileInput from '../ButtonFileInput';

export interface AlertModalProps {
  open: boolean,
  text: JSX.Element | JSX.Element[] | string,
  textCenter?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
  noCancel?: boolean;
  loading?: boolean;
  confirmButtonStyle?: CSSProperties;
  modalStyle?: CSSProperties;
  chooseFileButtonStyle?: CSSProperties;
  showChooseFileButton?: boolean;
  hideConfirmButton?: boolean;
  chooseFileButtonText?: string;
  onFileSelected?: (value: File | FileList | null) => void;
  acceptFileType?: string;
}

export declare type CustomizedAlertModalProps = AlertModalProps & { children?: ReactNode };

export const alertModalInitialState = {
  open: false, text: '', onConfirm: () => {}, onCancel: () => {},
};

function AlertModal({
  open = false,
  text,
  onConfirm,
  onCancel,
  confirmText = 'はい',
  cancelText = 'いいえ',
  noCancel = false,
  textCenter = false,
  loading = false,
  confirmButtonStyle = {},
  modalStyle = {},
  chooseFileButtonStyle = {},
  showChooseFileButton = false,
  hideConfirmButton = false,
  chooseFileButtonText,
  onFileSelected,
  acceptFileType,
}: AlertModalProps) {
  return (
    <div className={style.alertBox} style={{ display: open ? 'block' : 'none' }}>
      <div className={style.modalContainer}>
        <div className={style.modal} style={modalStyle}>
          <div className={textCenter === true ? [style.alertMessage, style.textCenter].join(' ') : style.alertMessage}>{text}</div>
          <div className={style.alertButtons}>
            { !hideConfirmButton && (<Button loading={loading} content={confirmText} size="smaller" onClick={onConfirm} style={confirmButtonStyle} />) }
            { !noCancel && <Button content={cancelText} size="smaller" color="lightGray" onClick={onCancel} />}
            { showChooseFileButton && (<ButtonFileInput accept={acceptFileType} onSelect={(e) => (onFileSelected ? onFileSelected(e) : '')} style={chooseFileButtonStyle} fileButtonText={chooseFileButtonText} />) }
          </div>
        </div>
      </div>
    </div>
  );
}

export interface AlertModalTemplateProps {
  open: boolean;
  children: ReactNode;
}

export type CloseAlertModalTextType = JSX.Element | JSX.Element[] | string;

export interface CloseAlertModalProps {
  open: boolean;
  text: CloseAlertModalTextType
  onCancel: () => void
  closeButtonText?: string
}

export interface PdfPreviewProps {
  open: boolean;
  onClose: () => void;
  pdfFile: File | null
  fileName: string;
}

export const closeModalInitialState = {
  open: false, text: '', onConfirm: () => {}, onCancel: () => {},
};

export const closeAlertModalWithJSXState: CloseAlertModalProps = {
  open: false, text: '', onCancel: () => {}, closeButtonText: '閉じる',
};

function AlertModalTemplate({
  open = false, children,
}: AlertModalTemplateProps) {
  return (
    <div className={style.alertBox} style={{ display: open ? 'block' : 'none' }}>
      <div className={style.modalContainer}>
        <div className={style.modal}>
          {children}
        </div>
      </div>
    </div>
  );
}

export function CloseAlertModal(props: CloseAlertModalProps) {
  const {
    open, text, onCancel, closeButtonText = '閉じる',
  } = props;
  return (
    <AlertModalTemplate open={open}>
      <div className={[style.alertMessage, style.textCenter].join(' ')}>{text}</div>
      <div className={style.alertButtons}>
        <Button content={closeButtonText} size="smaller" onClick={() => onCancel()} />
      </div>
    </AlertModalTemplate>
  );
}

export function CustomizeAlertModal({
  open = false,
  text,
  onConfirm,
  onCancel,
  confirmText = 'はい',
  cancelText = 'いいえ',
  noCancel = false,
  textCenter = false,
  loading = false,
  confirmButtonStyle = {},
  modalStyle = {},
  chooseFileButtonStyle = {},
  showChooseFileButton = false,
  hideConfirmButton = false,
  chooseFileButtonText,
  onFileSelected,
  acceptFileType,
  children,
}: CustomizedAlertModalProps) {
  return (
    <div className={style.alertBox} style={{ display: open ? 'block' : 'none' }}>
      <div className={style.modalContainer}>
        <div className={style.modal} style={modalStyle}>
          {children}
          <div className={style.alertButtons}>
            { !noCancel && <Button content={cancelText} size="smaller" color="lightGray" onClick={onCancel} />}
            { !hideConfirmButton && (<Button loading={loading} content={confirmText} size="smaller" onClick={onConfirm} style={confirmButtonStyle} />) }
            { showChooseFileButton && (<ButtonFileInput accept={acceptFileType} onSelect={(e) => (onFileSelected ? onFileSelected(e) : '')} style={chooseFileButtonStyle} fileButtonText={chooseFileButtonText} />) }
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertModal;
