import React, { useCallback, useEffect, useState } from 'react';
import { v1 as uuid } from 'uuid';
import styles from './docDetailModal.module.css';
import mainStyles from '../../pages/main.module.css';
import { DocumentDetail, DocumentUpdateForm } from '../../services/http/documents.api';
import Button from '../Button/button';
import SelectInput from '../SelectInput';
import PdfPreview from '../PdfPreview/PdfPreview';
import { useDocumentAttachmentDownload, useDocumentDownload } from '../../hooks/api/document.hook';
import { useDownloadFile } from '../../hooks/file.hook';
import { useGetDocumentClassificationAndTypeApi } from '../../hooks/api/documentManagement.hook';
import { DocumentClassificationAndType } from '../../services/http/documentManagement.api';
import { useAuthContext } from '../../store/auth.store';
import ControlCode from '../../utils/controlCode';
import { useGetRelatedTagsApi } from '../../hooks/api/tag.hook';
import { useCreateDownloadFileLogApi } from '../../hooks/api/log.hook';
// eslint-disable-next-line import/no-cycle
import DocDetailEdit from './docDetailEdit';
import { PdfPreviewModal } from '../PdfPreviewModal';

/**
 * 文書詳細モーダル’表示’のプロップスインターフェース
 */
export interface Props {
  /** 使用される文書の参照 */
  document: DocumentDetail | null;
  /** モーダルが閉じられるときに呼び出される関数 */
  onClose: (hidden:boolean) => void
  /** 文書が更新されるときに呼び出される関数 */
  onDocumentUpdated: () => void
  /** バージョンが削除されるきに呼び出される関数 */
  onRemoveDocumentVersion: (version: DocumentDetail['documents'][number]) => void,
  /** 文書が削除されるときに呼び出される関数 */
  onRemoveDocument: (document: DocumentDetail) => void,
  /** バージョンが更新されるときに呼び出される関数 */
  onUpdateVersion: (form: DocumentUpdateForm) => void,
  /** 文書が編集されているかどうか */
  setIsEditing: (isEditing: boolean) => void

  onCancelAddingRelatedDocuments: () => void
}

/**
 * 文書詳細モーダル’表示’コンポーネント
 */
function DocDetailInfo({
  document: documentRef,
  onRemoveDocumentVersion,
  onRemoveDocument,
  onUpdateVersion,
  onClose,
  onDocumentUpdated,
  setIsEditing,
  onCancelAddingRelatedDocuments,
}: Props) {
  // States
  const [edit, setEdit] = useState(false);
  const [documentVersion, setDocumentVersion] = useState<DocumentDetail['documents'][number] | null>(null);
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [documentClassificationIsTypeContractState, setDocumentClassificationIsTypeContractState] = useState(false);
  const [documentClassification, setDocumentClassification] = useState<DocumentClassificationAndType | undefined>(undefined);
  const { hasFolderPermission } = useAuthContext();
  const [documentClassificationAndTypeList, setDocumentClassificationAndTypeList] = useState<DocumentClassificationAndType[]>([]);
  const [pdfForModal, setPdfForModal] = useState<File | null>(null);

  // Apis
  const getDocumentClassificationAndTypeApi = useGetDocumentClassificationAndTypeApi();
  const documentDownload = useDocumentDownload();
  const documentAttachmentDownload = useDocumentAttachmentDownload();
  const downloadFile = useDownloadFile();
  const { request: createDownloadLog } = useCreateDownloadFileLogApi();

  // hooks
  const getDefaultTag = useGetRelatedTagsApi();

  // methods
  const downloadAttachmentFile = useCallback(async (attachFile: { id: number, fileName?: string }, pdfName: string) => {
    if (documentVersion) {
      const attachmentFile = await documentAttachmentDownload.request({ attachmentId: attachFile.id });
      downloadFile(attachmentFile, pdfName);
    }
  }, [documentAttachmentDownload, documentVersion, downloadFile]);

  const updateDocumentTags = (document: DocumentDetail) => {
    document.documents.forEach(async (doc) => {
      const requestTags = await getDefaultTag.request(doc.classification || 0, doc.typeId || 0);
      // eslint-disable-next-line no-param-reassign
      doc.tags = requestTags.map((tag) => ({
        format: tag.format,
        required: tag.require,
        tagLabel: tag.tagLabel,
        value: doc.tags.find((tagDoc) => (tagDoc.tagLabel === tag.tagLabel && tagDoc.format === tag.format))?.value || '',
      }));
    });
  };

  useEffect(() => {
    if (documentRef && documentRef.documents.length) {
      updateDocumentTags(documentRef);
      setDocumentVersion(documentRef.documents[0]);
    }
  }, [documentRef]);

  useEffect(() => {
    if (documentVersion) {
      getDocumentClassificationAndTypeApi.request().then(
        (response) => {
          const documentVersionClassification = response.find((item) => item.id === documentVersion.classification);
          const documentClassificationTypeContract = response.find((item) => item.name === '契約書');
          const documentClassificationIsTypeContract = documentClassificationTypeContract?.id === documentVersion.classification;
          setDocumentClassification(documentVersionClassification);
          setDocumentClassificationIsTypeContractState(documentClassificationIsTypeContract);
          setDocumentClassificationAndTypeList(response);
        },
      );
      if (documentVersion.fileName) {
        documentDownload.request({ documentDataId: documentVersion.dataId, fileName: documentVersion.fileName })
          .then(setPdfFile);
      } else {
        setPdfFile(null);
      }
    }
  }, [documentVersion]);

  const describeDocumentType = (docTypeId: number) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < documentClassificationAndTypeList.length; i++) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < documentClassificationAndTypeList[i].types.length; j++) {
        const docType = documentClassificationAndTypeList[i].types[j];
        if (docType.documentTypeId === docTypeId) {
          return docType.documentTypeName;
        }
      }
    }
    return '';
  };

  const handlePreviewPdf = () => {
    if (documentVersion) {
      createDownloadLog(documentVersion.dataId, 1);
    }
    setPdfForModal(pdfFile);
  };

  return edit && documentRef && documentVersion
    ? (
      <DocDetailEdit
        document={documentRef}
        onClose={() => {
          setEdit(false);
          onClose(true);
        }}
        onDocumentUpdated={onDocumentUpdated}
        onRemoveDocumentVersion={onRemoveDocumentVersion}
        onUpdateVersion={onUpdateVersion}
        onRemoveDocument={onRemoveDocument}
        editingVersionNo={documentVersion?.versionNo}
        onCancelAddingRelatedDocuments={onCancelAddingRelatedDocuments}
      />
    )
    : (
      <div className={styles.bodySection}>
        <div className={mainStyles['d-flex']}>
          <div className={mainStyles['flex-1']}>
            {(documentRef && hasFolderPermission(documentRef.folder, ControlCode.Write)) && (
              <Button
                size="smaller"
                onClick={() => {
                  setEdit(true);
                  setIsEditing(true);
                }}
              >
                編集
              </Button>
            )}
          </div>
          <div
            className={[mainStyles['flex-1'], mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['justify-content-end']].join(' ')}
          >
            <SelectInput
              className={[mainStyles['mr-4']].join(' ')}
              value={documentVersion}
              options={documentRef?.documents?.sort((a, b) => b.versionNo - a.versionNo).map((item) => ({ text: `第${item.versionNo}版`, value: item })) || []}
              onChange={setDocumentVersion}
              style={{ width: '170px' }}
            />
            <img className={[styles.topButtonsImg].join(' ')} src="/images/Close-333333.svg" alt="" onClick={() => onClose(true)} />
          </div>
        </div>
        <div className={styles.bodySectionMain}>
          <div className={styles.pdfContainer}>
            <PdfPreview className={styles.imagesContainerSection} pdfFile={pdfFile} pageHeight={250} />
            { pdfFile && (
              <img
                className={styles.arrowImg}
                src="/images/provisory-images/arrow-up-right.svg"
                alt=""
                onClick={handlePreviewPdf}
              />
            )}
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')}>
            <div className={[styles.label, mainStyles.expandDiv].join(' ')}>
              文書名
            </div>
            <div className={[styles.text, mainStyles.expandDiv].join(' ')} style={{ width: '650px' }}>
              {documentVersion?.name || ''}
            </div>
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')}>
            <div className={styles.label}>
              ファイル名
            </div>
            <div style={{ width: '350px' }} className={[styles.text, mainStyles.expandDiv].join(' ')}>
              {documentVersion?.fileName || ''}
            </div>
            <div style={{ marginLeft: '15px' }} className={[styles.text].join(' ')}>
              <Button
                size="smaller"
                onClick={() => {
                  if (documentVersion && pdfFile) {
                    downloadFile(pdfFile, documentVersion?.fileName);
                    createDownloadLog(documentVersion?.dataId, 0);
                  }
                }}
              >
                ダウンロード
              </Button>
            </div>
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')}>
            <div className={styles.label}>
              文書種類
            </div>
            <div className={styles.text}>
              { documentClassification?.name && (
                <>
                  {documentClassification?.name}
                  {' '}
                  :
                  {' '}
                </>
              ) }

              {documentVersion?.typeName || ''}
            </div>
          </div>
          {documentClassificationIsTypeContractState && (
            <>
              <div
                className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')}
              >
                <div className={styles.label}>契約種別</div>
                <div className={styles.text}>
                  {documentRef?.electronicFlg === 1 ? '電子契約' : '書面契約'}
                </div>
              </div>
              <div
                className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')}
              >
                <div className={styles.label}>書面有無</div>
                {/* eslint-disable-next-line no-nested-ternary */}
                <div className={styles.text}>{documentRef?.paperFlg === 1 ? '有り' : '無し'}</div>
              </div>
            </>
          )}
          <div className={[mainStyles['d-flex'], mainStyles['mt-20px']].join(' ')}>
            <div className={[styles.label, mainStyles.expandDiv].join(' ')}>
              文書ID
            </div>
            <div className={[styles.text, mainStyles.expandDiv].join(' ')} style={{ width: '650px' }}>
              {documentRef?.ccControlNumber || ''}
            </div>
          </div>
          {documentClassificationIsTypeContractState && (
            <>
              <div
                className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')}
              >
                <div className={styles.label}>
                  <div>
                    バーコード
                  </div>
                  {' '}
                  印字欄
                </div>
                {/* eslint-disable-next-line no-nested-ternary */}
                <div className={[styles.text, mainStyles.expandDiv].join(' ')} style={{ width: '650px' }}>
                  { documentRef?.barcodePrinting }
                </div>
              </div>
              <div
                className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')}
              >
                <div className={styles.label}>
                  <div>
                    保管品
                  </div>
                  {' '}
                  バーコード
                </div>
                {/* eslint-disable-next-line no-nested-ternary */}
                <div className={[styles.text, mainStyles.expandDiv].join(' ')} style={{ width: '650px' }}>
                  { documentRef?.itemCode }
                </div>
              </div>
            </>
          )}

          {documentVersion?.tags?.map((tag, index) => (
            <div className={`${mainStyles['d-flex']} ${mainStyles['mt-15px']}`} key={uuid()}>
              <div className={styles.label}>
                {index === 0 && 'タグ'}
              </div>
              <div className={`${styles.tagTextContent} ${styles.text}`}>
                <div className={[mainStyles['d-flex']].join(' ')}>
                  <div className={mainStyles.expandDiv} style={{ width: '150px', minWidth: '150px' }}>
                    {tag.tagLabel}
                  </div>
                  <div style={{ width: '20px' }}>
                    { tag.required && (
                    <div className={[mainStyles['text-danger']].join(' ')} style={{ marginLeft: '10px' }}>
                      ※
                    </div>
                    )}
                  </div>
                  <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                    {'  '}
                    :
                    {'  '}
                  </div>
                  <div className={[mainStyles['flex-4'], mainStyles.expandDiv].join(' ')}>
                    { tag.value}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')}>
            <div className={styles.label}>
              メモ
            </div>
            <div className={[mainStyles.expandDiv, styles.text].join(' ')} style={{ width: '650px' }}>
              {documentVersion?.memo || ''}
            </div>
          </div>
          { documentVersion?.relationalDocuments.map((relationalDocument, i) => (
            <div className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')} key={uuid()}>
              <div className={styles.label}>
                {i === 0 && '関連文書'}
              </div>
              <div style={{ width: '200px', minWidth: '200px' }} className={[styles.text, mainStyles.expandDiv].join(' ')}>
                文書ID:
                {' '}
                {relationalDocument.ccControlNumber}
              </div>
              <div style={{ width: '200px', minWidth: '200px' }} className={[styles.text, mainStyles.expandDiv].join(' ')}>
                文書名:
                {' '}
                {relationalDocument.name}
              </div>
              <div style={{ marginLeft: '15px' }} className={[styles.text, mainStyles.expandDiv].join(' ')}>
                文書種類:
                {' '}
                {describeDocumentType(+relationalDocument.type)}
              </div>
            </div>
          ))}
          { documentVersion?.attachmentFileList.map((attachmentFile, i) => (
            <div className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')} key={uuid()}>
              <div className={styles.label}>
                {i === 0 && '添付ファイル'}
              </div>
              <div style={{ width: '350px', display: 'flex' }} className={styles.text}>
                <div style={{ minWidth: '54px' }}>
                  文書名:
                </div>
                {' '}
                <div className={mainStyles.expandDiv}>
                  {attachmentFile.fileName}
                </div>
              </div>
              <div style={{ marginLeft: '15px' }} className={styles.text}>
                <Button
                  size="smaller"
                  onClick={() => {
                    downloadAttachmentFile(attachmentFile, attachmentFile.fileName);
                  }}
                >
                  ダウンロード
                </Button>
              </div>
            </div>
          ))}
          <div className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')}>
            <div className={styles.label}>
              フォルダ
            </div>
            <div className={styles.text}>
              { documentRef?.folder.name}
            </div>
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['mt-15px'], mainStyles['mb-20px']].join(' ')}>
            <div className={styles.label}>
              ステータス
            </div>
            <div className={[mainStyles['flex-1'], styles.text].join(' ')} style={{ minWidth: '70%' }}>
              { documentRef && documentRef.warehouseStatus }
            </div>
          </div>
        </div>
        {documentVersion && (
        <PdfPreviewModal
          open={pdfForModal !== null}
          onClose={() => setPdfForModal(null)}
          pdfFile={pdfForModal}
          fileName={documentVersion?.fileName || ''}
          fileId={documentVersion?.dataId || 0}
          showOcrSearchPreview={documentVersion?.documentFileOcrFlg === true}
          documentVersionNo={documentVersion?.versionNo}
          documentId={documentRef?.id}
        />
        )}
      </div>
    );
}

export default DocDetailInfo;
