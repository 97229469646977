import { useState } from 'react';
import { useHttp } from './api.hook';
import documentsApi from '../../services/http/documents.api';

/**
 * PDF文書詳細検索フック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDocumentSearchPdfDetail() {
  return useHttp(documentsApi.searchPdfDetail);
}

/**
 * 文書詳細検索フック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDocumentSearchDetail() {
  return useHttp(documentsApi.searchDetail);
}

/**
 * 文書フォルダ検索フック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDocumentSearchFolder() {
  return useHttp(documentsApi.searchFolder);
}

/**
 * 文書取得詳細フック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDocumentGetDetail() {
  return useHttp(documentsApi.getDetail);
}

/**
 * 文書登録フック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDocumentRegister() {
  return useHttp(documentsApi.register);
}

/**
 * 文書ダウンロードフック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDocumentDownload() {
  return useHttp(documentsApi.downloadFile);
}

/**
 * 添付文書ダウンロードフック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDocumentAttachmentDownload() {
  return useHttp(documentsApi.downloadAttachment);
}

/**
 * 文書更新フック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDocumentUpdate() {
  return useHttp(documentsApi.update);
}

/**
 * 文書復習フック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDocumentRevision() {
  return useHttp(documentsApi.revision);
}

/**
 * 文書削除フック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDocumentDelete() {
  return useHttp(documentsApi.delete);
}

/**
 * 文書復習削除フック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useDocumentDeleteRevision() {
  return useHttp(documentsApi.deleteRevision);
}

/**
 * 文書フォルダの移動フック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useMoveDocumentFolder() {
  return useHttp(documentsApi.moveFolder);
}

/**
 * OCR詳細を取得するフック
 *
  * @returns 次のプロパティを持つオブジェクト:
 *   - `request()`:APIにリクエストを行う関数
 *   - `exec()`:APIにリクエストを行う関数
 *   - `data`:リクエストが成功した場合のレスポンスデータ
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `loading`: リクエスト中かどうか
 */
export function useGetOcrDetail() {
  return useHttp(documentsApi.getOcrDetail);
}

/**
 * 文書表示モードのタイプ
 */
type DisplayMode = 'list' | 'grid' | 'largeGrid';
const DOCUMENT_DISPLAY_MODE_KEY = 'documentDisplayMode';

/**
 * 文書表示モードを設定するためのフック
 *
 * @param defaultValue - 初期表示モード。省略時は 'list'。
 * @param onChange - 表示モードが変更された時に呼び出されるコールバック関数。
 * @returns 表示モードを制御するためのオブジェクト。
 *   - `DisplayMode`:現在の表示モード。
 *   - `setDisplayMode`:表示モードを変更するための関数。引数に新しい表示モードを指定して呼び出します。
 *   - `toggleDisplayMode`:表示モードをトグルするための関数。
 */
export function useDocumentDisplayMode(defaultValue?: DisplayMode, onChange?: (mode: DisplayMode) => void) {
  const localStorageDisplayMode = (localStorage.getItem(DOCUMENT_DISPLAY_MODE_KEY) as DisplayMode) || null;
  // Use this line below, if the toggle button is enabled.
  // const [displayMode, setDisplayMode] = useState<DisplayMode>(localStorageDisplayMode || defaultValue || 'list');
  const [displayMode, setDisplayMode] = useState<DisplayMode>('list');

  return {
    displayMode,
    setDisplayMode: (newDisplayMode: DisplayMode) => {
      let displayMd = newDisplayMode;
      if (newDisplayMode === 'list') displayMd = 'grid';
      if (newDisplayMode === 'grid') displayMd = 'largeGrid';
      if (newDisplayMode === 'largeGrid') displayMd = 'list';

      setDisplayMode(displayMd);
      localStorage.setItem(DOCUMENT_DISPLAY_MODE_KEY, displayMd);
      if (onChange) onChange(newDisplayMode);
    },
    toggleDisplayMode: () => {
      let newDisplayMode: DisplayMode = displayMode;
      if (displayMode === 'list') newDisplayMode = 'grid';
      if (displayMode === 'grid') newDisplayMode = 'largeGrid';
      if (displayMode === 'largeGrid') newDisplayMode = 'list';
      setDisplayMode(newDisplayMode);
      localStorage.setItem(DOCUMENT_DISPLAY_MODE_KEY, newDisplayMode);
      if (onChange) onChange(newDisplayMode);
    },
  };
}
