import { useState } from 'react';
import { ApiError } from '../../services/http';
import MailActivateApi, { MailActivateForm } from '../../services/http/mailActivate.api';

/**
 * メールアクティベートAPIフック
 *
 * @returns 次のプロパティを持つオブジェクト:
 *   - `loading`: リクエスト中かどうか
 *   - `error`:リクエストが失敗した場合のエラーオブジェクト
 *   - `request`:リクエストを行う関数。
 */
// eslint-disable-next-line import/prefer-default-export
export function useMailActivateApi() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);
  const [data, setData] = useState<{ message: string } | null>(null);

  async function request(form: MailActivateForm): Promise<null> {
    setLoading(true);
    try {
      const res = await MailActivateApi.mailActivate(form);
      setData(res);
      return null;
    } catch (e) {
      setError(e as ApiError);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return {
    loading, error, request, data,
  };
}
