import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import style from './ocrPreviewModal.module.css';
import mainStyles from '../../pages/main.module.css';
import Button from '../Button/button';
// eslint-disable-next-line import/no-cycle
import PdfOCRPreview from '../PdfPreview/PdfOcrPreview';
import CopyButton from '../CopyButton/CopyButton';
import { useGetOcrDetail } from '../../hooks/api/document.hook';
import Spinner from '../Spinner';
import { OcrResult } from '../../services/http/documents.api';
import PdfPreviewSideSlide from '../PdfPreview/PdfPreviewSideSlide';
import BaseText from '../BaseText/BaseText';

export interface PdfPreviewProps {
  open: boolean;
  onClose: () => void;
  pdfFile: File | null;
  documentId?: number;
  versionNo?: number;
}

export function OcrPreviewModal({
  open = false,
  onClose,
  pdfFile,
  documentId,
  versionNo,
}: PdfPreviewProps) {
  // APIs
  const { exec: requestOcrDetail, data: ocrDetailData, loading: ocrLoading } = useGetOcrDetail();

  // refs
  const ocrContainerRef = useRef<HTMLDivElement>(null);

  // states
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOCRData, setSelectedOCRData] = React.useState<OcrResult | null>(null);
  const [copiedText, setCopiedText] = useState('');

  // const's
  const [copiedItemId, setCopiedItemId] = useState<string>('');

  // methods
  const handleCloseModal = useCallback(() => { onClose(); }, [onClose]);

  // effects
  useEffect(() => {
    if (!documentId || !versionNo) return;
    requestOcrDetail({ documentId, versionNo });
  }, [documentId, versionNo]);

  useEffect(() => {
    if (ocrContainerRef.current) {
      setCopiedText(ocrContainerRef.current.innerText);
    }
  }, [selectedOCRData]);

  useEffect(() => {
    const newData = ocrDetailData?.ocrResultList.find((item) => item.pageNo === currentPage) || null;
    setSelectedOCRData(newData);
  }, [currentPage, ocrDetailData?.ocrResultList]);

  useEffect(() => {
    localStorage.removeItem('pdfjs.history');
    return () => {
      localStorage.removeItem('pdfjs.history');
    };
  }, []);

  return (
    <div className={style.modalOverlay} style={{ display: open ? 'block' : 'none' }}>
      <div className={style.alertBox}>
        <div className={style.modal}>
          <div className={style.modalContainer}>
            <div className={style.modalBody}>
              { ocrLoading ? (
                <div className={style.loadingContainer}>
                  <Spinner />
                </div>
              )
                : (
                  <>
                    <PdfPreviewSideSlide setCurrentPage={setCurrentPage} className={style.imagesContainerSection} pdfFile={pdfFile} />
                    <div className={style.pdfFileContainer}>
                      <PdfOCRPreview
                        ocrData={ocrDetailData?.ocrResultList}
                        pdfFile={pdfFile}
                        currentPage={currentPage}
                      />
                    </div>
                    <div className={style.ocrContainer}>
                      <div className={style.copyButtonWrapper}>
                        <BaseText size="smMd">{ocrDetailData?.documentName}</BaseText>
                        <CopyButton
                          textToCopy={copiedText}
                          itemId="callbackUrl"
                          setCopiedItemId={setCopiedItemId}
                          copiedItemId={copiedItemId}
                          showTooltip={false}
                        />
                      </div>
                      <div className={style.ocrContainerBody} ref={ocrContainerRef}>
                        {selectedOCRData?.ocrDetailResultList.map((item, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <div key={index} className={style.ocrItem}>
                            {item.ocrText}
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
            </div>
            <div className={style.modalFooter}>
              <Button className={mainStyles['mr-10']} color="lightGray" size="smaller" onClick={handleCloseModal}>
                閉じる
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
