import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import * as PDFJS from 'pdfjs-dist';
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/types/src/pdf';
import { PageViewport } from 'pdfjs-dist/types/src/display/display_utils';
import styles from './pdfPreviewSideSlide.module.css';

function fileToArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = (ev) => reject(ev.target?.error);
    reader.onload = (ev) => resolve(ev.target?.result as ArrayBuffer);
    reader.readAsArrayBuffer(file);
  });
}

async function loadPdf(pdfFile: File): Promise<PDFDocumentProxy> {
  const buffer = await fileToArrayBuffer(pdfFile);
  return PDFJS.getDocument({
    data: buffer,
    cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${PDFJS.version}/cmaps/`,
    cMapPacked: true,
  }).promise as Promise<PDFDocumentProxy>;
}

function createPageWrapper() {
  const pageWrapper = document.createElement('div');
  pageWrapper.classList.add(styles.pageWrapper);
  return pageWrapper;
}

interface PdfPreviewProps {
  className?: string;
  pdfFile: File | null | undefined;
  style?: React.CSSProperties;
  setCurrentPage?: (page: number) => void;
}

function createCanvasElement(viewport: PageViewport) {
  const canvas = document.createElement('canvas');
  canvas.classList.add(styles.canvasPointer);

  canvas.style.maxWidth = '100%';
  canvas.style.maxHeight = '100%';
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  canvas.style.border = '1px solid #d2d2d2';
  canvas.style.boxShadow = 'none';
  canvas.style.cursor = 'pointer';

  return canvas;
}

function createPageNumberElement(pageIndex: number) {
  const pageNumber = document.createElement('div');
  pageNumber.innerText = `${pageIndex + 1}`;
  pageNumber.style.marginBottom = '5px';
  pageNumber.style.fontSize = '10px';
  pageNumber.style.fontWeight = 'bold';
  pageNumber.style.textAlign = 'center';
  pageNumber.style.color = '#555';
  return pageNumber;
}

export default function PdfPreviewSideSlide({
  className, pdfFile, style, setCurrentPage,
}: PdfPreviewProps) {
  // refs
  const pdfContainerRef = useRef<HTMLDivElement>(null);
  const isRenderingRef = useRef(false);
  const pageCanvasesRef = useRef<HTMLCanvasElement[]>([]);

  // states
  const [selectedPage, setSelectedPage] = useState<number | null>(1);
  const [canvasListUpdated, setCanvasListUpdated] = useState(0);

  // methods
  const resetPdfContainer = useCallback((container: HTMLDivElement) => {
    // eslint-disable-next-line no-param-reassign
    container.innerHTML = '';
    pageCanvasesRef.current = [];
  }, []);

  const renderPage = useCallback((page: PDFPageProxy, pageIndex: number, pdfContainer: HTMLDivElement) => {
    const pageWrapper = createPageWrapper();

    const viewport = page.getViewport({ scale: 1 });
    const pageNumber = createPageNumberElement(pageIndex);
    const canvas = createCanvasElement(viewport);

    pageWrapper.appendChild(canvas);
    pageWrapper.appendChild(pageNumber);
    pdfContainer.appendChild(pageWrapper);
    pageCanvasesRef.current.push(canvas);

    page.render({
      canvasContext: canvas.getContext('2d') as CanvasRenderingContext2D,
      viewport,
    });

    return canvas;
  }, []);

  const renderPages = useCallback((pages: PDFPageProxy[]) => {
    const pdfContainer = pdfContainerRef.current;

    if (!pdfContainer) return;

    resetPdfContainer(pdfContainer);

    pages.forEach((page, pageIndex) => {
      const canvas = renderPage(page, pageIndex, pdfContainer);

      canvas.addEventListener('click', () => {
        setCurrentPage?.(pageIndex + 1);
        setSelectedPage(pageIndex + 1);
      });
    });
  }, [renderPage, resetPdfContainer, setCurrentPage]);

  const loadPages = useCallback((pdf: PDFDocumentProxy) => Promise.all(
    Array.from({ length: pdf.numPages }, (_, i) => pdf.getPage(i + 1)),
  ), []);

  const refreshPdf = useCallback(async () => {
    if (isRenderingRef.current) return;
    isRenderingRef.current = true;

    if (!pdfFile) return;

    const pdf = await loadPdf(pdfFile);
    const pages = await loadPages(pdf);

    renderPages(pages);

    setCanvasListUpdated((prev) => prev + 1);
  }, [loadPages, pdfFile, renderPages]);

  // effects
  useEffect(() => {
    if (!pageCanvasesRef.current.length) return;
    pageCanvasesRef.current.forEach((canvas, index) => {
      if (canvas instanceof HTMLCanvasElement) {
        const updatedCanvas = canvas;
        updatedCanvas.style.border = selectedPage === index + 1 ? '1px solid #007bff' : '1px solid #d2d2d2';
        updatedCanvas.style.boxShadow = selectedPage === index + 1 ? '0px 4px 12px rgba(0, 123, 255, 0.1)' : 'none';
      }
    });
  }, [selectedPage, canvasListUpdated]);

  useEffect(() => {
    if (!pdfFile || isRenderingRef.current) return;
    refreshPdf();
  }, [pdfFile]);

  return (
    <div
      className={[styles.container, className].join(' ')}
      ref={pdfContainerRef}
      style={{ ...style, width: '100% !important' }}
    />
  );
}
