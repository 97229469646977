import {
  createContext, ReactNode, useContext, useMemo, useState,
} from 'react';
import { Folder } from '../services/http/folder.api';
import ControlCode, { ControlCodeItemOnlyCode } from '../utils/controlCode';
import { Document } from '../services/http/documents.api';

export enum UserType {
  Terada = 1,
  General = 2,
}

export enum ManagementType {
  Admin = 1,
  General = 2,
}

interface ChangeUser {
  customerName: string
  userId: string
  userName :string
}

interface User {
  userId: string,
  userName: string,
  email: string,
  userType: UserType,
  authorityList: [ControlCodeItemOnlyCode],
  managementType: ManagementType,
  passwordUpdate: boolean,
  changeUser?: ChangeUser,
  hasOcrSupport: boolean,
}

export function getUserType(user?: User | null): UserType | null {
  if (!user) return null;
  if (user.changeUser) {
    return UserType.General;
  }
  return user.userType;
}

type AuthContextType = {
  token: string | null,
  user: User | null,
  setUser: (user: User) => void,
  removeUser: () => void
  setToken: (token: string) => void,
  removeToken: () => void,
  hasPermission: (controlCode: ControlCode) => boolean,
  hasFolderPermission: (folder: Folder | null, controlCode: ControlCode) => boolean
  hasDocumentPermission: (document: Document | null, controlCode: ControlCode) => boolean
};

export const AuthContext = createContext<AuthContextType>(
  {
    token: null,
    user: null,
    setUser: () => ({}),
    removeUser: () => ({}),
    setToken: () => ({}),
    removeToken: () => ({}),
    hasPermission: () => false,
    hasFolderPermission: () => false,
    hasDocumentPermission: () => false,
  },
);

const localUser = localStorage.getItem('user');

interface AuthProviderProps { children: ReactNode[] | ReactNode }

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState(localUser ? JSON.parse(localUser) : null);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const value = useMemo(() => ({
    user,
    token,
    setUser(newUser: User) {
      localStorage.setItem('user', JSON.stringify(newUser));
      setUser(newUser);
    },
    removeUser() {
      localStorage.removeItem('user');
      setUser(null);
    },
    setToken(newToken: string) {
      localStorage.setItem('token', newToken);
      setToken(newToken);
    },
    removeToken() {
      localStorage.removeItem('token');
      setToken(null);
    },
    hasPermission(controlCode: ControlCode) {
      if (!user) return false;
      const u = user as User;
      if (getUserType(u) === UserType.Terada) return true;
      return u.authorityList.some((c) => c.controlCode === controlCode);
    },
    hasFolderPermission(folder: Folder | null, controlCode: ControlCode) {
      if (!user) return false;
      const u = user as User;
      if (getUserType(u) === UserType.Terada) return true;
      if (u.managementType === ManagementType.Admin) return true;
      if (!folder) return false;
      return folder.authorityList.some((c) => c.controlCode === controlCode);
    },
    hasDocumentPermission(document: Document | null, controlCode: ControlCode) {
      if (!user) return false;
      const u = user as User;
      if (getUserType(u) === UserType.Terada) return true;
      if (u.managementType === ManagementType.Admin) return true;
      if (!document) return false;
      return document.authorityList.some((c) => c.controlCode === controlCode);
    },
  }), [user, token]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export type { User };
export const useAuthContext = () => useContext(AuthContext);
export default AuthProvider;
