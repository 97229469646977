import { InputHTMLAttributes } from 'react';
import styles from './customSwitch.module.css';

export default function CustomSwitch(props: InputHTMLAttributes<HTMLInputElement>) {
  const { style, ...rest } = props;
  return (
    <label className={styles.switch} style={style}>
      <input
        type="checkbox"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      <span className={styles.slider} />
    </label>
  );
}
