import { useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './OrganizationAuthorizationSaml.module.css';
import usePageTitle from '../../hooks/title.hook';
import { useUpdateOrganizationAuthentication } from '../../hooks/api/organizationAuthentication.hook';
import BaseText from '../../components/BaseText/BaseText';
import Button from '../../components/Button/button';
import Input from '../../components/Input/input';
import TextArea from '../../components/TextArea/TextArea';
import routes from '../../utils/routes';
import Divider from '../../components/Divider/Divider';
import { useLog } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import { useLoadingOverlay, useMessageModal } from '../../hooks/modal.hook';
import Formatter from '../../utils/formatters';
import ButtonFileInput from '../../components/ButtonFileInput';
import mainStyles from '../main.module.css';
import LoadingOverlay from '../../components/LoadingOverlay';
import CopyButton from '../../components/CopyButton/CopyButton';
import AlertModal, { alertModalInitialState } from '../../components/AlertModal';
import Breadcrumb from '../../components/Breadcrumb';
import SamlStatusIcon, { SamlAuthStatus } from '../../components/SamlStatusIcon/SamlStatusIcon';
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch';

function Component() {
  usePageTitle('シングルサインオン設定');
  const navigate = useNavigate();

  const openMessageModal = useMessageModal();
  const [copiedItemId, setCopiedItemId] = useState<string>('');
  const [modal, setModal] = useState(alertModalInitialState);
  const [isForcingSamlAuthentication, setIsForcingSamlAuthentication] = useState(false);
  const [metadataFileName, setMetadataFileName] = useState<string>('');
  const [selectedMetadataFile, setSelectedMetadataFile] = useState<File | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isFormLoaded, setIsFormLoaded] = useState(false);

  const {
    form, update, loading, authentication: { value: authentication, loading: authLoading }, isValidate, getOrganizationSaml,
  } = useUpdateOrganizationAuthentication();
  useLoadingOverlay(loading);
  const log = useLog(LogFormName.OrganizationAuthenticationSaml);

  const isOverallLoading = loading || authLoading || !isInitialized;

  function toCertificateStatus(certificateExpiration: Date) {
    // @ts-ignore
    const diffTime = Math.abs(certificateExpiration - new Date());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 30) return SamlAuthStatus.Valid;
    if (diffDays <= 30) return SamlAuthStatus.Warning;
    return SamlAuthStatus.Invalid;
  }

  async function validateForm() {
    if (!form.settingName.value.trim()) {
      await openMessageModal('設定名を入力してください。');
      return false;
    }
    if (form.settingName.value.length > 250) {
      await openMessageModal('設定名は250文字以内で入力してください。');
      return false;
    }
    if (!form.entityId.value.trim()) {
      await openMessageModal('Entity IDを入力してください。');
      return false;
    }
    if (!form.loginUrl.value.trim()) {
      await openMessageModal('Sign on URLを入力してください。');
      return false;
    }
    if (!form.certificate.value.trim()) {
      await openMessageModal('証明書を入力してください。');
      return false;
    }
    return true;
  }

  async function onSaveButtonClick() {
    log.add(LogControlName.Edit);

    const isValid = await validateForm();
    if (!isValid) return;

    setModal({
      text: '保存してよろしいですか？',
      open: true,
      onCancel: () => setModal({ ...modal, open: false }),
      onConfirm: async () => {
        setModal({ ...modal, open: false });
        try {
          await update();
          await getOrganizationSaml.execute();
          await openMessageModal('SSO設定を更新しました。');
          setSelectedMetadataFile(null);
          setMetadataFileName('');
        } catch (error) {
          await openMessageModal((error as Error).message);
        }
      },
    });
  }

  function onMetadataSelected(file?: File | FileList | null) {
    if (!file) return;
    if (file instanceof File) {
      setMetadataFileName(file.name);
      setSelectedMetadataFile(file);
    }
  }

  const formatCertificate = (certificateString: string) => certificateString
    .split(/\r?\n/)
    .filter((line) => !line.includes('BEGIN CERTIFICATE') && !line.includes('END CERTIFICATE'))
    .join('');

  const onCertificateFileSelectedParseAndSetToString = (file?: File | FileList | null) => {
    if (!file) return;
    const singleFile = file instanceof FileList ? file[0] : file;
    const reader = new FileReader();

    reader.onload = (e) => {
      const fileContent = e.target?.result;
      if (typeof fileContent === 'string') {
        const formattedContent = formatCertificate(fileContent);
        form.certificate.set(formattedContent);
      }
    };
    reader.readAsText(singleFile);
  };

  const onClickForceSamlAuthentication = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsForcingSamlAuthentication(true);
    form.force.set(event.target.checked);
  };

  function exportXml() {
    log.add(LogControlName.Download);

    const xml = `
<EntityDescriptor entityID="${authentication?.tenantCode}" >
    <SPSSODescriptor protocolSupportEnumeration="urn:oasis:names:tc:SAML:2.0:protocol">
       <AssertionConsumerService 
          Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST"
          Location="${authentication?.callbackUrl}"
          index="1"/>
    </SPSSODescriptor>
</EntityDescriptor>
`;

    const blob = new Blob([xml], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'cloudcabinet_metadata.xml';
    a.click();
    URL.revokeObjectURL(url);
  }

  useEffect(() => {
    if (!form.force.value || !isForcingSamlAuthentication) return;
    openMessageModal('SAML認証を強制をONにすると保存を行った時点で \n すべてのユーザーがメールアドレスとパスワードでの \n ログインができなくなりますのでご注意ください。');
  }, [form.force.value, isForcingSamlAuthentication, openMessageModal]);

  useEffect(() => {
    if (!loading && !authLoading) {
      setIsInitialized(true);
    }
  }, [loading, authLoading]);

  useEffect(() => {
    if (!isOverallLoading) {
      setIsFormLoaded(true);
    }
  }, [form]);

  return (
    <MainFrame borderBox>
      <div className={styles.container}>
        <Breadcrumb crumbs={[
          { label: 'マスタ管理', route: routes.masterDataSettingGeneral },
          { label: 'シングルサインオン' }]}
        />
        <div className={[styles.header].join(' ')}>
          <BaseText bold justify="center">SAML SSO設定</BaseText>
        </div>
        {!isOverallLoading
          ? (
            <div className={styles.containerBody}>
              <div className="mt-4 mb-4">
                <BaseText className="mb-1">
                  設定名
                  <span className={` ${mainStyles['text-danger']} }`} style={{ marginLeft: '10px' }}>※</span>
                </BaseText>
                <div className="col-7 mb-4 ml-4" style={{ maxWidth: 650 }}><Input value={form.settingName.value} onChange={form.settingName.set} /></div>
                <BaseText>機能の有効化</BaseText>
                <div className="d-flex align-items-center mt-3 ml-4">
                  <div className={styles.label}>
                    <BaseText>SAML認証を有効化</BaseText>
                  </div>
                  {isFormLoaded
                    && (
                      <div>
                        <CustomSwitch
                          checked={form.enable.value}
                          onChange={(event) => form.enable.set(event.target.checked)}
                        />
                      </div>
                    )}
                </div>
                <div className="d-flex align-items-center mt-2 ml-4">
                  <div className={styles.label}>
                    <BaseText className="">SAML認証を強制</BaseText>
                  </div>
                  {isFormLoaded && (
                    <div className="">
                      <CustomSwitch
                        checked={form.force.value}
                        onChange={onClickForceSamlAuthentication}
                        disabled={!form.enable.value}
                      />
                    </div>
                  )}
                </div>
                <Divider className=" mt-4 mb-2" />
                <div className="d-flex">
                  <BaseText className="mr-3">外部ID（IdP）から取得する情報</BaseText>
                </div>
                <div className="col-12 pl-20px ">
                  <div className="mt-3">
                    <BaseText className="col-12 mb-2">
                      メタデータ(xmlファイル)から設定を読み込む
                    </BaseText>
                    <div className="mt-2 d-flex">
                      <ButtonFileInput
                        style={{ minWidth: 142 }}
                        fileButtonText="ファイルを選択"
                        onSelect={(e) => onMetadataSelected(e)}
                        accept="text/xml"
                      />

                      {selectedMetadataFile && (
                        <BaseText className="col-12 mb-2">
                          <>
                            {' '}
                            <BaseText className="ml-2">
                              {metadataFileName}
                            </BaseText>
                          </>
                        </BaseText>
                      )}
                    </div>
                    <div>
                      <Button
                        className="mt-3"
                        size="smaller"
                        color="lighterGray"
                        onClick={() => form.metadataFile.set(selectedMetadataFile)}
                      >
                        設定に反映する
                      </Button>
                    </div>
                  </div>
                  <Divider className=" mt-4 mb-2" />
                  <div className="pr-5">
                    <BaseText className="mt-2 mb-1">
                      Entity ID
                      <span className={` ${mainStyles['text-danger']} }`} style={{ marginLeft: '10px' }}>※</span>
                    </BaseText>
                    <div className="col-7" style={{ maxWidth: 650 }}><Input value={form.entityId.value} onChange={form.entityId.set} /></div>
                    <BaseText className="mt-2 mb-1">
                      Sign on URL
                      <span
                        className={` ${mainStyles['text-danger']} }`}
                        style={{ marginLeft: '10px' }}
                      >
                        ※
                      </span>
                    </BaseText>
                    <div className="col-7" style={{ maxWidth: 650 }}><Input value={form.loginUrl.value} onChange={form.loginUrl.set} /></div>
                    <div className="mb-1 mt-3 align-items-center">
                      <div className="d-flex align-items-center">
                        <BaseText className="mt-2 mb-1">
                          X.509証明書
                          <span
                            className={[mainStyles['text-danger'], mainStyles['mr-3']].join(' ')}
                            style={{ marginLeft: '10px', fontWeight: 'initial' }}
                          >
                            ※
                          </span>
                        </BaseText>
                        {form.certificate.expiresAt
                          && <SamlStatusIcon status={toCertificateStatus(form.certificate.expiresAt)} />}
                        {
                          form.certificate.expiresAt
                          && (
                            <BaseText className="ml-2">
                              有効期限：
                              {Formatter.toDisplayDate(form.certificate.expiresAt, Formatter.defaultDateFormat)}
                            </BaseText>
                          )
                        }
                      </div>
                      <ButtonFileInput
                        className="mb-2"
                        fileButtonText="証明書を読み込む"
                        onSelect={(e) => onCertificateFileSelectedParseAndSetToString(e)}
                        accept=".pem,.cer"
                      />
                    </div>
                    <TextArea
                      value={form.certificate.value}
                      rows={15}
                      onChange={form.certificate.set}
                      style={{ height: 150 }}
                    />
                  </div>
                </div>
                <Divider className=" mt-4 mb-2" />
                {authentication && (
                  <>
                    <div className="col-12 mb-3">
                      <BaseText>
                        外部ID（IdP）へ登録する情報
                        <Button
                          className="ml-3"
                          size="smaller"
                          color="lighterGray"
                          onClick={() => exportXml()}
                        >
                          ダウンロード
                        </Button>
                      </BaseText>
                    </div>
                    <div className="d-flex wrap ml-4 ">
                      <BaseText className="col-12 ">Entity ID</BaseText>
                      <div className="d-flex mb-3">
                        <BaseText className="col-12 pl-20px mr-2">{authentication.tenantCode}</BaseText>
                        <CopyButton
                          textToCopy={authentication.tenantCode}
                          itemId="tenantCode"
                          setCopiedItemId={setCopiedItemId}
                          copiedItemId={copiedItemId}
                        />
                      </div>
                      <BaseText className="col-12 ">ACS URL</BaseText>
                      <div className="d-flex mb-3">
                        <BaseText className="col-12 pl-20px mr-2">{authentication.callbackUrl}</BaseText>
                        <CopyButton
                          textToCopy={authentication.callbackUrl}
                          itemId="callbackUrl"
                          setCopiedItemId={setCopiedItemId}
                          copiedItemId={copiedItemId}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              {
                authentication && (
                  <div className="d-flex wrap mb-4 w-100">
                    <BaseText className="col-12">登録更新情報</BaseText>
                    <Divider className="mb-2  w-100" />
                    <div className="d-flex wrap col-3 pl-5">
                      <BaseText className="col-12" size="sm">登録者</BaseText>
                      <BaseText className="col-12" size="sm">{authentication.createdBy.name}</BaseText>
                      <BaseText className="col-12 " size="sm">更新者</BaseText>
                      <BaseText className="col-12" size="sm">{authentication.updatedBy.name}</BaseText>
                    </div>
                    <div className="d-flex wrap col-3">
                      <BaseText className="col-12" size="sm">登録日時</BaseText>
                      <BaseText className="col-12" size="sm">
                        {Formatter.toDisplayDate(authentication.createdBy.at, Formatter.defaultDateTimeFormat)}
                      </BaseText>

                      <BaseText className="col-12 " size="sm">更新日時</BaseText>
                      <BaseText className="col-12" size="sm">
                        {Formatter.toDisplayDate(authentication.updatedBy.at, Formatter.defaultDateTimeFormat)}
                      </BaseText>
                    </div>
                  </div>
                )
              }
            </div>
          ) : (
            <div className={styles.containerBody} />
          )}
        <div className={[styles.footer, mainStyles['border-top']].join(' ')}>
          <div className={styles.footers}>
            <Button size="smaller" color="lighterGray" onClick={() => navigate(routes.masterDataSettingGeneral)}>
              キャンセル
            </Button>
            <Button size="smaller" onClick={() => onSaveButtonClick()} disabled={!isValidate}>保存</Button>
          </div>
        </div>
        {isOverallLoading ? <LoadingOverlay /> : null}
        <AlertModal open={modal.open} text={modal.text} onCancel={modal.onCancel} onConfirm={modal.onConfirm} />
      </div>
    </MainFrame>
  );
}

export default Component;
