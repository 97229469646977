/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
import { useNavigate } from 'react-router-dom';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  ColDef,
  FirstDataRenderedEvent,
  ICellRendererParams,
  RowGroupOpenedEvent,
  ValueGetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import Mark from 'mark.js';
import mainStyles from '../../pages/main.module.css';
import styles from '../MainFrameRightSection/mainFrameRightSection.module.css';
import routes from '../../utils/routes';
import {
  useDocumentDisplayMode,
  useDocumentDownload,
  useDocumentGetDetail,
} from '../../hooks/api/document.hook';
import {
  Document, DocumentDetail, SortList,
} from '../../services/http/documents.api';
import { Folder } from '../../services/http/folder.api';
import BaseTable from '../BaseTable';
import Button from '../Button/button';
// eslint-disable-next-line import/no-cycle
import DocDetailModalFrame from '../DocDetailModal/docDetailModalFrame';
// eslint-disable-next-line import/no-cycle
import {
  DocumentsAndSearchType,
  Pagination,
  mainPageTableName,
} from '../MainFrameRightSection/mainFrameRightSection';
import BreadcrumbStyle from '../Breadcrumb/breadcrumb.module.css';
import AGUtils from '../../utils/ag-grid.utils';
import PaginationJp from '../BaseTable/partials/PaginationJp/mannual';
import { useGetAllTagsApi } from '../../hooks/api/tag.hook';
import RelationalDocumentsDetailCellRenderer from '../BaseTable/partials/relationalDocumentsDetailCellRenderer/relationalDocumentsDetailCellRenderer';
import { useMessageModal } from '../../hooks/modal.hook';
import { ApiError } from '../../services/http';
import FileUtils from '../../utils/file.utils';
import LoadingOverlay from '../LoadingOverlay';
import { WarehouseStatusCode } from '../../utils/warehouse.utils';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { ContractType, WarehouseStatusType } from '../SearchInputTop';
import Checkbox from '../Checkbox';
import PdfIcon from '../../assets/images/pdf-icon.png';
import { STATUS_MESSAGES } from '../../utils/messages';

/**
 * メインフレームの右セクションのプロップスインターフェース
 */
export interface Props {
  /** 選択されているフォルダ */
  folder: Folder | null,
  /** 関連文書を選択した時のコールバック */
  onChooseRelatedDocuments: (documents: Document[]) => void,
  /** 関連文書の追加をキャンセルした時のコールバック */
  onCancelAddingRelatedDocuments: () => void,
  // --------------------------------------------------
  documentsAndSearchType: DocumentsAndSearchType,
  pagination: Pagination,
  isSearching: boolean,
  highlightTexts: string[],
  warehouseStatus: WarehouseStatusType,
  contractType: ContractType,
  onPageChanged: (page: number) => void,
  onSortChanged: (sortList: SortList[]) => void,
  onRemoveDocument: (document: DocumentDetail) => void,
  onRefresh: () => void,
  isSearchingOcr: boolean,
}

/**
 * メインフレームの右セクションのコンポーネント
 */
function MainFrameRightAttachingSection({
  folder,
  onChooseRelatedDocuments,
  onCancelAddingRelatedDocuments,
  // ----------------------
  documentsAndSearchType,
  pagination,
  isSearching,
  highlightTexts,
  warehouseStatus,
  contractType,
  onPageChanged,
  onSortChanged,
  onRemoveDocument,
  onRefresh,
  isSearchingOcr,
}: Props) {
  // refs
  const inputFile = useRef<HTMLInputElement | null>(null);
  const tableRef = useRef<AgGridReact<Document>>(null);

  // States
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const [selectDocument, setSelectedDocument] = useState<Document | null>(null);

  const [detailedDocument, setDetailedDocument] = useState<DocumentDetail | null>(null);

  const selectedDocumentsRef = useRef<Document[]>(selectedDocuments);

  // Hooks
  const { displayMode } = useDocumentDisplayMode();
  const { allTags, reload: getAllTags, loadingAllTags } = useGetAllTagsApi();
  const navigate = useNavigate();

  const openMessageModal = useMessageModal();
  const documentGetDetail = useDocumentGetDetail();
  const { request: documentDownload, loading: documentDownloadLoading } = useDocumentDownload();

  // constants
  const cantGoNext = pagination.page === pagination.totalPages;
  const cantGoPrevious = pagination.page === 1;
  const isDocumentDraggable = false;

  const { request: createLog } = useCreateLogApi();

  // Methods
  const onPdfPreviewRequest = useCallback((doc: Document) => {
    if (doc) {
      try {
        createLog(LogFormName.Main, LogControlName.Download);
        documentGetDetail.request({ id: doc.id }).then(setDetailedDocument);
      } catch (e) {
        openMessageModal((e as ApiError)?.message);
      }
    }
  }, [createLog, documentGetDetail, openMessageModal]);

  const onPdfRequest = useCallback((pdf: File | null) => {
    if (pdf) FileUtils.previewPdf(pdf);
  }, []);

  useEffect(() => {
    if (detailedDocument) {
      if (detailedDocument.documents.length === 0) {
        openMessageModal('PDFファイルが見つかりませんでした。');
        return;
      }
      const current = detailedDocument.documents.sort((a, b) => b.versionNo - a.versionNo)[0];
      documentDownload({ documentDataId: current.dataId, fileName: current.fileName })
        .then(onPdfRequest)
        .catch(() => openMessageModal('PDFファイルが見つかりませんでした。'));
    }
  }, [detailedDocument, documentDownload, onPdfRequest, openMessageModal]);

  const toggleSelectDocument = useCallback((document: Document) => {
    const selectedDocument = selectedDocuments.find((d) => d.id === document.id);
    if (selectedDocument) {
      setSelectedDocuments(selectedDocuments.filter((d) => d.id !== selectedDocument.id));
    } else {
      setSelectedDocuments([...selectedDocuments, document]);
    }
  }, [selectedDocuments]);

  const onFirstDataRendered = useCallback((e: FirstDataRenderedEvent<Document>) => e.api?.forEachNodeAfterFilter((node) => {
    node.setSelected(selectedDocuments.some((document) => node.data?.id === document.id), true, true);
  }), [selectedDocuments]);

  const MarkJsInstance = useMemo(() => new Mark('.highlightTarget'), []);

  const unMarkText = useCallback(() => MarkJsInstance?.unmark(), [MarkJsInstance]);

  const markText = useCallback((texts: string[]) => {
    const markJsOptions = { acrossElements: true };
    MarkJsInstance?.mark(texts, markJsOptions);
  }, [MarkJsInstance]);

  const highLightSearch = useCallback((texts: string[]) => {
    setTimeout(() => {
      unMarkText();
      markText(texts);
    }, 300);
  }, [markText, unMarkText]);

  const onDragStart = useMemo(() => (dragEvent: React.DragEvent<HTMLDivElement>, document: Document) => {
    const documentFolderId = folder ? folder.id : document.folderId;
    let selectedDocumentsToTransfer: { document: Document, folderId: number }[] = [];
    if (selectedDocumentsRef.current.length > 0) {
      const filteredDocuments = selectedDocumentsRef.current.filter((doc) => doc.requiredTagInputFlg === 1);
      selectedDocumentsToTransfer = filteredDocuments.map((doc) => {
        const docFolderId = folder ? folder.id : doc.folderId;
        return { document: doc, folderId: docFolderId };
      });
    } else {
      selectedDocumentsToTransfer = [{ document, folderId: documentFolderId }];
    }
    const { userAgent } = window.navigator;
    const isIE = userAgent.indexOf('Trident/') >= 0;
    dragEvent?.dataTransfer?.setData(
      isIE ? 'text' : 'text/plain',
      JSON.stringify({
        documentsTransfer: selectedDocumentsToTransfer,
        folderId: folder?.id,
      }),
    );
  }, [folder]);

  const relationalDocumentsDetailCellRenderer = (e: { data: Document }) => (
    <RelationalDocumentsDetailCellRenderer
      relationalDocumentList={e.data.relationalDocumentList}
    />
  );

  const onExpandRelationalDocumentDetailRow = (params: ICellRendererParams) => {
    const documentExists = params.node.data.relationalDocumentList.length;
    const detailRowIsExpanded = tableRef.current?.api.getDisplayedRowAtIndex(params.node.rowIndex as number)?.expanded;

    if (!documentExists) return;
    if (!detailRowIsExpanded) {
      tableRef.current?.api.getDisplayedRowAtIndex(params.node.rowIndex as number)?.setExpanded(true);
    } else {
      tableRef.current?.api.getDisplayedRowAtIndex(params.node.rowIndex as number)?.setExpanded(false);
    }
  };

  const onFileSelected = useCallback((value: FileList | null, folderParam: Folder | null) => {
    if (value && folderParam) {
      navigate(routes.csvImportScreen, { state: { file: value[0], folderId: folderParam.id } });
    }
  }, [navigate]);

  const handleAttachDocuments = useCallback(() => {
    onChooseRelatedDocuments(selectedDocuments);
  }, [onChooseRelatedDocuments, selectedDocuments]);

  const onRowGroupOpened = (params: RowGroupOpenedEvent<Document>) => {
    if (params.node.expanded) {
      params.api.forEachNode((node) => {
        if (
          node.expanded
                    && node.id !== params.node.id
        ) {
          node.setExpanded(false);
        }
      });
    }
  };

  const closeModal = () => {
    setTimeout(() => {
      setSelectedDocument(null);
    }, 100);
  };

  // Memos

  const filteredDocuments = useMemo(() => {
    let result = documentsAndSearchType.documents;
    // if (warehouseStatus) result = result.filter((item) => item.warehouseStatus === WarehouseStatus.NOT_RECEIVED);
    if (warehouseStatus !== WarehouseStatusType.ALL) {
      result = result.filter((item) => {
        if (warehouseStatus === WarehouseStatusType.NOT_STORED) return item.warehouseStatusCode === WarehouseStatusCode.NOT_RECEIVED;
        if (warehouseStatus === WarehouseStatusType.STORED) return item.warehouseStatusCode === WarehouseStatusCode.IN_STORAGE;
        return true;
      });
    }
    if (contractType !== ContractType.ALL) {
      result = result.filter((item) => {
        if (contractType === ContractType.ELECTRONIC) return item.electronicFlg;
        if (contractType === ContractType.PAPER) return item.paperFlg;
        return true;
      });
    }
    return result;
  }, [contractType, documentsAndSearchType.documents, warehouseStatus]);

  const noRowsText = useMemo(() => {
    if (filteredDocuments.length !== 0) return '　';
    if (!folder && !isSearching) return 'フォルダを選択してください';
    return STATUS_MESSAGES.NO_DATA;
  }, [filteredDocuments.length, folder, isSearching]);

  const colsDefs = useMemo((): ColDef<Document>[] => {
    const cols: ColDef[] = [];
    cols.push(...[
      {
        headerName: 'Checkbox',
        field: 'checkbox',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        maxWidth: 50,
        lockPosition: true,
        suppressColumnsToolPanel: true,
      },
      {
        headerName: '文書名',
        resizable: true,
        sortable: true,
        field: 'name',
        width: 250,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (params: ICellRendererParams) => (
          <div
            className={mainStyles['ml-3']}
            style={{ alignContent: 'center', display: 'flex' }}
            draggable={params.node.data.requiredTagInputFlg === 1 ? isDocumentDraggable : false}
            onDragStart={(e) => onDragStart(e, params.node.data)}
          >
            {params.node.data.fileName
              ? (
                <img
                  src="/images/Pdf-icon.svg"
                  alt="pdf"
                  onClick={() => onPdfPreviewRequest(params.node.data)}
                />
              )
              : (
                <img
                  src="/images/Pdf-icon-semitransparent.svg"
                  alt="pdf"
                />
              )}
            {isSearching ? (
              <div className={[mainStyles['ml-2'], mainStyles['text-overflow'], styles.docName].join(' ')}>
                <div className={styles.folderName}>
                  {params.node.data.folderName}
                </div>
                <div
                  className={[mainStyles['ml-2'], mainStyles['text-overflow'], styles.docName, 'highlightTarget'].join(' ')}
                >
                  {params.value}
                </div>
              </div>
            ) : (
              <span className={[mainStyles['ml-2'], mainStyles['text-overflow'], 'highlightTarget'].join(' ')}>
                {params.value}
              </span>
            )}
            {params.node.data.requiredTagInputFlg !== 1 && (
            <div style={{ marginLeft: 'auto' }}>
              <img src="/images/Icon-awesome-exclamation.svg" alt="exclamation" style={{ height: '12px', marginRight: '5px' }} />
            </div>
            )}
          </div>
        ),
      }, {
        headerName: '',
        resizable: true,
        sortable: true,
        field: 'relationalDocumentCount',
        width: 90,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (params: ICellRendererParams<Document>) => (
          <button
            type="button"
            className={params.value ? styles.tableButton : styles.tableButtonDisabled}
            onClick={() => {
              onExpandRelationalDocumentDetailRow(params);
            }}
          >
            <img src="/images/Icon-awesome-link.svg" alt="" />
            {` ${params.value}`}
          </button>
        ),
        headerComponentParams: {
          template: '<div style={{ visibility: "hidden" }}></div>',
        },
      },
      AGUtils.colDefault('typeName', '文書種類'),
      AGUtils.colDefault('warehouseStatus', 'ステータス'),
      AGUtils.colDefault('fileName', 'ファイル名', 170, true, true),
      AGUtils.colFileSize('fileSize', 'サイズ'),
      {
        headerName: '契約種別',
        field: 'electronicFlg',
        resizable: true,
        sortable: true,
        valueGetter: (params: ValueGetterParams<Document>) => {
          if (!params.data) return null;
          if (params.data.electronicFlg === 1) return '電子契約';
          if (params.data.electronicFlg === 0) return '書面契約';
          return null;
        },
        width: 100,
      },
      {
        headerName: '書面有無',
        resizable: true,
        sortable: true,
        field: 'paperFlg',
        width: 105,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (params: ICellRendererParams<Document>) => (
          <button
            type="button"
            className={params.value ? styles.tableButton : styles.tableButtonDisabled}
          >
            書面
          </button>
        ),
      },
      AGUtils.colDefault('ccControlNumber', '文書ID'),
      AGUtils.colDefault('barcodePrinting', 'バーコード印字欄', 150, true, true),
      AGUtils.colDefault('itemCode', '保管品バーコード', 150, true, true),
      AGUtils.colUsername('registUser', '登録者'),
      AGUtils.colDate('registDate', '登録日時'),
      AGUtils.colUsername('updateUser', '更新者'),
      AGUtils.colDate('updateDate', '更新日時'),
    ]);

    const tags = new Set<string>(allTags.map((tag) => tag.tagLabel));
    const unique = Array.from(tags);

    unique.forEach((tag) => {
      const apiTag = allTags.find(({ tagLabel }) => (tagLabel === tag)) ?? null;
      cols.push({
        field: `${tag}`,
        headerName: tag,
        resizable: true,
        sortable: true,
        width: 160,
        cellClass: 'highlightTarget',
        valueGetter: AGUtils.getTagValueGetter(tag, apiTag),
      });
    });

    if (isSearchingOcr) {
      cols.push({
        headerName: '検索結果',
        field: 'ocrResult',
        cellClass: 'highlightTarget',
        resizable: true,
        width: 300,
      });
    }

    return cols;
  }, [allTags, folder, onDragStart, isDocumentDraggable, isSearching, isSearchingOcr]);

  // Effects
  useEffectOnce(() => {
    getAllTags();
  });

  useEffect(() => {
    setSelectedDocuments([]);
  }, [folder]);

  useEffect(() => {
    selectedDocumentsRef.current = selectedDocuments;
  }, [selectedDocuments]);

  return (
    <div className={styles.mainFrameRightSection}>
      <div className={BreadcrumbStyle.box} />

      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={(e) => onFileSelected(e.target.files, folder)}
        accept=".csv"
      />
      <div
        className={[styles.mainFrameRightSectionTop, selectedDocuments.length > 0 ? styles.topMinWidth : ''].join(' ')}
      >
        <div className={styles.mainFrameRightSectionTopButtons}>
          <Button
            color="lightGray"
            className={styles.attachDocumentButton}
            size="smaller"
            onClick={() => {
              onCancelAddingRelatedDocuments();
            }}
          >
            キャンセル
          </Button>
          <Button
            className={styles.attachDocumentButton}
            size="smaller"
            onClick={handleAttachDocuments}
          >
            関連文書を追加する
          </Button>
          {/* Keep this button hidden for now. This was a client request */}
          {/* {folder && ( */}
          {/* <Button */}
          {/*  outline */}
          {/*  className={styles.listButton} */}
          {/*  onClick={toggleDisplayMode} */}
          {/* /> */}
          {/* )} */}
        </div>
      </div>
      { displayMode === 'list' && (
      <BaseTable<Document>
        tableRef={tableRef}
        formName={mainPageTableName}
        waitToLoad={loadingAllTags}
        rowData={filteredDocuments}
        columnDefs={colsDefs}
        rowSelection="multiple"
        suppressRowClickSelection
        onSelectionChanged={(e) => setSelectedDocuments(e.api.getSelectedRows())}
        onFirstDataRendered={onFirstDataRendered}
        minWidthProp={selectedDocuments.length > 0 ? '1141px' : '0'}
        sideBar
        pagination
        paginationPageSize={500}
        suppressPaginationPanel
        masterDetail
        detailCellRenderer={relationalDocumentsDetailCellRenderer}
        detailRowAutoHeight
        onRowGroupOpened={onRowGroupOpened}
        embedFullWidthRows
        suppressMenuHide={false}
        rowDragManaged
        rowDragMultiRow
        sortChangedHandler={onSortChanged}
        onRowDataUpdated={() => { highLightSearch(highlightTexts); }}
        onBodyScroll={() => { highLightSearch(highlightTexts); }}
        noRowsText={noRowsText}
        createLogOnDownloadTableData={LogFormName.DocDetail_Attachment}
      />
      )}
      {displayMode === 'grid' && (
      <div className={[styles.fileGridContainer].join(' ')}>
        {
              filteredDocuments.map((document) => (
                <div
                  className={[mainStyles['p-2'], styles.fileCard].join(' ')}
                  key={document.id}
                  draggable={isDocumentDraggable}
                  onDragStart={(e) => onDragStart(e, document)}
                >
                  <Checkbox
                    className={styles.fileCardCheckbox}
                    value={selectedDocuments.some((item) => item.id === document.id)}
                    onChange={() => toggleSelectDocument(document)}
                  />
                  <div
                    className={styles.fileCardImageContainer}
                    aria-hidden
                  >
                    {document.fileName
                      ? <img src={PdfIcon} alt="pdf" height={50} />
                      : <div style={{ height: '50px' }} />}
                  </div>
                  <span className={styles.fileCardTitle}>{document.name}</span>
                </div>
              ))
          }
      </div>
      )}
      {displayMode === 'largeGrid' && (
      <div className={[styles.fileGridContainer].join(' ')}>
        {
              filteredDocuments.map((document) => (
                <div
                  className={[mainStyles['p-2'], styles.fileCardLarge].join(' ')}
                  key={document.id}
                  draggable={isDocumentDraggable}
                  onDragStart={(e) => onDragStart(e, document)}
                >
                  <Checkbox
                    className={styles.fileCardCheckbox}
                    value={selectedDocuments.some((item) => item.id === document.id)}
                    onChange={() => toggleSelectDocument(document)}
                  />
                  <div
                    className={styles.fileCardImageContainer}
                    aria-hidden
                  >
                    {document.fileName
                      ? <img src={PdfIcon} alt="pdf" height={100} />
                      : <div style={{ height: '100px' }} />}
                  </div>
                  <span className={styles.fileCardTitle}>{document.name}</span>
                </div>
              ))
          }
      </div>
      )}
      { pagination.totalItems > 0 && (
      <PaginationJp
        totalPage={pagination.totalPages}
        pageInfo={{ pageLimit: pagination.pageLimit, page: pagination.page, total: pagination.totalItems }}
        onBtFirst={() => {
          if (cantGoPrevious) return;
          onPageChanged(1);
        }}
        onBtLast={() => {
          if (cantGoNext) return;
          onPageChanged(pagination.totalPages);
        }}
        onBtNext={() => {
          if (cantGoNext) return;
          onPageChanged(pagination.page + 1);
        }}
        onBtPrevious={() => {
          if (cantGoPrevious) return;
          onPageChanged(pagination.page - 1);
        }}
      />
      )}
      {selectDocument && (
      <DocDetailModalFrame
        document={selectDocument}
        onClose={closeModal}
        onRemoveDocument={onRemoveDocument}
        onDocumentUpdated={onRefresh}
        onCancelAddingRelatedDocuments={onCancelAddingRelatedDocuments}
      />
      )}

      {documentDownloadLoading ? <LoadingOverlay /> : null}
    </div>
  );
}

export default MainFrameRightAttachingSection;
