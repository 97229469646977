export enum WarehouseStatusCode {
  REQUESTING_TO_RECEIVE = 'RequestingToReceive',
  IN_STORAGE = 'InStorage',
  REQUESTING_TO_DISPATCH = 'RequestingToDispatch',
  OUT_OF_WAREHOUSE = 'OutOfWarehouse',
  REQUESTING_DISPOSAL = 'RequestingDisposal',
  DISPOSED = 'Disposed',
  NOT_RECEIVED = 'NotReceived',
  REQUESTING_SCAN = 'RequestingScan',
}

export enum DeliveryTime {
  NO_SPECIFIC_TIME = '90', // 時間指定なし
  MORNING = '91', // 午前
  AFTERNOON = '92', // 午後
  EVENING = '93', // 夕方
}
