import React, {
  useEffect, useState,
} from 'react';
import style from './pdfPreviewModal.module.css';
import mainStyles from '../../pages/main.module.css';
import Button from '../Button/button';
import { useCreateDownloadFileLogApi } from '../../hooks/api/log.hook';
import { OcrPreviewModal } from '../OcrPreviewModal';

export interface PdfPreviewProps {
  open: boolean;
  onClose: () => void;
  pdfFile: File | null;
  fileName: string;
  fileId: number;
  showOcrSearchPreview?: boolean,
  documentVersionNo?: number,
  documentId?: number,
}

export function PdfPreviewModal({
  open = false,
  onClose,
  pdfFile,
  fileName,
  fileId,
  showOcrSearchPreview = false,
  documentVersionNo,
  documentId,
}: PdfPreviewProps) {
  const { request: createDownloadLog } = useCreateDownloadFileLogApi();

  const [dataUrl, setDataUrl] = useState<string | null>(null);
  const [openOcrPreviewModal, setOpenOcrPreviewModal] = useState(false);

  const downloadFile = (file: File | null, pdfFileName: string) => {
    if (!file) return;
    createDownloadLog(fileId, 0);

    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = pdfFileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleCloseModal = () => {
    setDataUrl(null);
    onClose();
  };

  useEffect(() => {
    if (pdfFile) {
      setDataUrl(URL.createObjectURL(pdfFile));
    }
  }, [pdfFile]);

  useEffect(() => {
    localStorage.removeItem('pdfjs.history');

    return () => {
      localStorage.removeItem('pdfjs.history');
    };
  }, []);

  return (
    <div className={style.modalOverlay} style={{ display: open ? 'block' : 'none' }}>
      <div className={style.alertBox}>
        <div className={style.modal}>
          <div className={style.modalContainer}>
            <div className={style.modalBody}>
              {dataUrl && (
                <iframe src={`pdfjs/web/viewer.html?file=${dataUrl}`} style={{ width: '100%', height: '100%' }} title="pdf" />
              )}
            </div>
            <div className={style.modalFooter}>
              <Button className={mainStyles['mr-10']} color="lightGray" size="smaller" onClick={handleCloseModal}>閉じる</Button>
              {showOcrSearchPreview && (
              <Button
                className={mainStyles['mr-10']}
                color="lightGray"
                size="smaller"
                onClick={() => {
                  setOpenOcrPreviewModal(true);
                }}
              >
                OCR結果
              </Button>
              )}
              <Button
                size="smaller"
                onClick={() => {
                  downloadFile(pdfFile, fileName);
                }}
              >
                ダウンロード
              </Button>
            </div>
          </div>
        </div>
      </div>
      {openOcrPreviewModal && (
      <OcrPreviewModal
        open
        onClose={() => {
          setOpenOcrPreviewModal(false);
        }}
        pdfFile={pdfFile}
        documentId={documentId}
        versionNo={documentVersionNo}
      />
      )}
    </div>
  );
}
