import React, { useCallback, useEffect, useRef } from 'react';
import * as PDFJS from 'pdfjs-dist';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/pdf';
import type { PDFPageProxy } from 'pdfjs-dist/types/src/display/api';
import { PageViewport } from 'pdfjs-dist/types/src/display/display_utils';
import styles from './pdfPreview.module.css';
// eslint-disable-next-line import/no-cycle
import { OcrDetailResult, OcrResult } from '../../services/http/documents.api';

async function fileToArrayBuffer(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = (ev) => reject(ev.target?.error);
    reader.onload = (ev) => resolve(ev.target?.result);
    reader.readAsArrayBuffer(file);
  });
}

function drawRedRectangle(canvas: HTMLCanvasElement, ocrResult: OcrDetailResult) {
  const context = canvas.getContext('2d');
  if (context) {
    context.strokeStyle = 'blue';
    context.lineWidth = 2;

    const width = ocrResult.rightUpX - ocrResult.leftUpX;
    const height = ocrResult.leftUnderY - ocrResult.leftUpY;

    context.strokeRect(ocrResult.leftUpX, ocrResult.leftUpY, width, height);
  }
}

async function loadPdfDocument(pdfFile: File | null) {
  if (!pdfFile) return null;
  const buffer = await fileToArrayBuffer(pdfFile);
  return PDFJS.getDocument({
    data: buffer,
    cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${PDFJS.version}/cmaps/`,
    cMapPacked: true,
  }).promise as Promise<PDFDocumentProxy>;
}

function applyScale(page: PDFPageProxy, pdfContainer: HTMLDivElement, scaleType?: 'page-width' | 'page-fit') {
  let viewport = page.getViewport({ scale: 1 });
  if (scaleType) {
    if (scaleType === 'page-width') {
      const scale = (pdfContainer.clientWidth / viewport.width) * window.devicePixelRatio;
      viewport = page.getViewport({ scale });
    } else if (scaleType === 'page-fit') {
      const scale = Math.min(
        pdfContainer.clientWidth / viewport.width,
        pdfContainer.clientHeight / viewport.height,
      );
      viewport = page.getViewport({ scale });
    }
  }

  return viewport;
}

function renderOcrData(canvas: HTMLCanvasElement, ocrData?: OcrResult[], currentPage?: number) {
  const currentOcrData = ocrData?.find((item) => item.pageNo === currentPage);
  if (!currentOcrData) return;

  currentOcrData.ocrDetailResultList.forEach((ocrResult) => {
    drawRedRectangle(canvas, ocrResult);
  });
}

function createCanvas(viewport: PageViewport) {
  const canvas = document.createElement('canvas');
  canvas.width = viewport.width;
  canvas.height = viewport.height;

  canvas.style.maxWidth = '100%';
  canvas.style.maxHeight = '100%';
  canvas.style.display = 'block';
  canvas.style.margin = 'auto';
  return canvas;
}

async function renderPageToCanvas(
  page: PDFPageProxy,
  canvas: HTMLCanvasElement,
  viewport: PageViewport,
) {
  await page.render({ canvasContext: canvas.getContext('2d') as CanvasRenderingContext2D, viewport }).promise;
  return canvas;
}

interface PdfOCRPreviewProps {
  pdfFile: File | null | undefined;
  ocrData?: OcrResult[];
  currentPage?: number;
}

export default function PdfOCRPreviewComponent({
  pdfFile,
  ocrData,
  currentPage,
}: PdfOCRPreviewProps) {
  // refs
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  // methods
  const clearPdfContainer = useCallback(() => {
    const pdfContainer = pdfContainerRef.current;
    if (pdfContainer) {
      while (pdfContainer.firstChild) {
        pdfContainer.removeChild(pdfContainer.firstChild);
      }
    }
  }, []);

  const renderPage = useCallback(async (pdf: PDFDocumentProxy, pageNumber: number) => {
    const pdfContainer = pdfContainerRef.current;

    if (!pdfContainer) {
      return;
    }

    const page = await pdf.getPage(pageNumber);
    clearPdfContainer();
    const viewport = applyScale(page, pdfContainer);
    const canvas = createCanvas(viewport);
    pdfContainer.appendChild(canvas);

    const pageRendered = await renderPageToCanvas(page, canvas, viewport);

    renderOcrData(pageRendered, ocrData, pageNumber);
  }, [clearPdfContainer, ocrData]);

  const refreshPdf = useCallback(async (currentPageParam: number) => {
    if (!pdfFile) return;

    const pdf = await loadPdfDocument(pdfFile);
    if (!pdf) return;

    renderPage(pdf, currentPageParam);
  }, [pdfFile, renderPage]);

  // effects
  useEffect(() => {
    if (currentPage === undefined || !pdfFile) return;
    refreshPdf(currentPage);
  }, [currentPage, pdfFile]);

  return (
    <div
      className={[styles.pdfContainerOcr].join(' ')}
      ref={pdfContainerRef}
      style={{ width: '100% !important' }}
    />
  );
}
