import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { v1 as uuid } from 'uuid';
// eslint-disable-next-line import/no-extraneous-dependencies
import TextareaAutosize from '@mui/base/TextareaAutosize';
import styles from './docDetailModal.module.css';
import mainStyles from '../../pages/main.module.css';
import {
  Document,
  DocumentClassificationOption,
  DocumentDetail,
  DocumentUpdateForm,
} from '../../services/http/documents.api';
import {
  useDocumentAttachmentDownload,
  useDocumentDelete,
  useDocumentDeleteRevision,
  useDocumentDownload,
  useDocumentRevision,
  useDocumentUpdate,
} from '../../hooks/api/document.hook';
import PdfPreview from '../PdfPreview/PdfPreview';
import SelectInput from '../SelectInput';
import Button from '../Button/button';
import { useAlertModal, useMessageModal } from '../../hooks/modal.hook';
import BaseModal from '../BaseModal';
import style from '../AlertModal/alertModal.module.css';
import { ApiError } from '../../services/http';
import { useGetRelatedTagsApi } from '../../hooks/api/tag.hook';
import { useGetDocumentClassificationAndTypeApi } from '../../hooks/api/documentManagement.hook';
import { DocumentClassificationAndType } from '../../services/http/documentManagement.api';
import { DropDownMenuItem } from '../DropdownMenu';
import { useDownloadFile } from '../../hooks/file.hook';
import FileInputButton from '../ButtonFileInput';
import RadioGroupInput from '../RadioGroupInput';
import TagInput from '../TagInput';
// eslint-disable-next-line import/no-cycle
import ChooseRelatedDocument from '../../pages/docRegisterScreen/chooseRelatedDocument';
import { useAuthContext } from '../../store/auth.store';
import ControlCode from '../../utils/controlCode';
import { useCreateDownloadFileLogApi, useCreateLogApi } from '../../hooks/api/log.hook';
import { PdfPreviewModal } from '../PdfPreviewModal';
import InputAutoSize from '../InputAutoSize/inputAutoSize';
/**
 * 関連文書のインターフェース
 */
export interface RelationalDocument {
  id?: number;
  name?: string;
  type?: string;
  duplicateTags?: { label: string; value: string; }[];
}

/**
 * 文書詳細モーダル’編集’のプロップスインターフェース
 */
export interface Props {
  /** 使用される文書の参照 */
  document: DocumentDetail | null;
  /** バージョンが削除されるきに呼び出される関数 */
  onRemoveDocumentVersion: (version: DocumentDetail['documents'][number]) => void,
  /** 文書が削除されるときに呼び出される関数 */
  onRemoveDocument: (doc: DocumentDetail) => void,
  /** バージョンが更新されるときに呼び出される関数 */
  onUpdateVersion: (form: DocumentUpdateForm) => void,
  /** モーダルが閉じられるときに呼び出される関数 */
  onClose: (hidden:boolean) => void
  /** 文書が更新されるときに呼び出される関数 */
  onDocumentUpdated: () => void
  /** 編集中のバージョン番号 */
  editingVersionNo: number;

  onCancelAddingRelatedDocuments: () => void;
}

/**
 * 文書詳細モーダル’編集’のコンポーネント
 */
function DocDetailEdit({
  document: documentRef, onRemoveDocumentVersion, onUpdateVersion, onRemoveDocument, onClose, onDocumentUpdated, editingVersionNo, onCancelAddingRelatedDocuments,
} :Props) {
  const inputAttachmentFile = useRef<HTMLInputElement | null>(null);
  const fileSelectorRef = useRef<HTMLInputElement>(null);
  const fileAttachmentRefs = useRef<HTMLInputElement[]>([]);

  const [operationModal, setOperationModal] = useState(false);
  const [documentVersion, setDocumentVersion] = useState<DocumentDetail['documents'][number] | null>(null);
  const [documentClassifications, setDocumentClassifications] = useState<DocumentClassificationAndType[]>([]);
  const [documentPrintedTypeSelected, setDocumentPrintedTypeSelected] = useState<'ELECTRONIC' | 'PAPER'>('ELECTRONIC');
  const [choosingRelatedDocument, setChoosingRelatedDocument] = useState(false);
  const [editingVersionState, setEditingVersionState] = useState<any>();
  const [isDeletionDisabled, setIsDeletionDisabled] = useState(false);
  const [pdfForModal, setPdfForModal] = useState<File | null>(null);

  const { hasFolderPermission } = useAuthContext();

  const [form, setForm] = useState<DocumentUpdateForm>({
    attachmentFileList: [],
    barcodePrinting: '',
    classification: 0,
    electronicFlg: documentRef?.electronicFlg || 0,
    file: undefined,
    fileName: '',
    fileSize: 0,
    fileId: undefined,
    memo: '',
    paperFlg: documentRef?.paperFlg || 0,
    relationalDocumentList: [],
    tagList: [],
    typeId: 0,
    typeName: '',
    name: '',
    id: 0,
  });

  const documentDownload = useDocumentDownload();
  const documentUpdate = useDocumentUpdate();
  const documentRevision = useDocumentRevision();
  const documentDelete = useDocumentDelete();
  const documentDeleteRevision = useDocumentDeleteRevision();
  const openAlertModal = useAlertModal();
  const openMessageModal = useMessageModal();
  const getDocumentClassificationAndTypeApi = useGetDocumentClassificationAndTypeApi();
  const getDefaultTag = useGetRelatedTagsApi();
  const downloadFile = useDownloadFile();
  const documentAttachmentDownload = useDocumentAttachmentDownload();
  const { request: createLog } = useCreateLogApi();
  const { request: createDownloadLog } = useCreateDownloadFileLogApi();
  const [fileHasChanged, setFileHasChanged] = useState(false);

  const updateVersion = useCallback(async (version: DocumentDetail['documents'][number]) => {
    setIsDeletionDisabled(!(documentRef?.documents[0]?.versionNo === version.versionNo));

    const newForm = {
      ...form,
      id: documentRef?.id ?? 0,
      barcodePrinting: documentRef?.barcodePrinting ?? '',
      name: version.name,
      memo: version.memo,
      fileSize: version.fileSize,
      fileName: version.fileName,
      fileId: version.fileName ? version.fileId : undefined,
      typeId: version.typeId,
      classification: version.classification,
      typeName: version.typeName,
      tagList: version.tags || [],
      relationalDocumentList: version.relationalDocuments,
      attachmentFileList: version.attachmentFileList || [],
    };
    setDocumentVersion(version);
    setEditingVersionState(newForm);
    setForm(newForm);
    try {
      if (version.fileName) {
        const pdfFile = await documentDownload.request({ documentDataId: version.dataId, fileName: version.fileName });
        if (pdfFile) {
          newForm.file = pdfFile;
          newForm.fileName = pdfFile.name;
        }
      }
      setDocumentPrintedTypeSelected(documentRef?.electronicFlg === 1 ? 'ELECTRONIC' : 'PAPER');
      getDocumentClassificationAndTypeApi.request().then(setDocumentClassifications);
    } catch (e) {
      await openMessageModal((e as ApiError)?.message);
    }
  }, [documentDownload, documentRef?.barcodePrinting, documentRef?.electronicFlg, documentRef?.id, form, getDocumentClassificationAndTypeApi, openMessageModal]);

  const updateClassificationType = useCallback(async (option: DocumentClassificationOption) => {
    const newForm = {
      ...form,
      classification: option.documentClassification.id || 0,
      typeId: option.type.documentTypeId || 0,
      typeName: option.type.documentTypeName,
      barcodePrinting: '',
    };
    setForm(newForm);
    try {
      const res = await getDefaultTag.request(option.documentClassification.id || 0, option.type.documentTypeId || 0);
      setForm({
        ...newForm,
        tagList: res.map((item) => ({
          format: item.format, value: '', tagLabel: item.tagLabel, required: item.require,
        })),
      });
    } catch (e) {
      await openMessageModal((e as ApiError)?.message);
    }
  }, [form, getDefaultTag, openMessageModal]);

  const setDocumentVersionTagValue = useCallback((tag: DocumentUpdateForm['tagList'][number], key: keyof DocumentDetail['documents'][number]['tags'][number], value: string) => {
    if (!form) return;
    setForm({ ...form, tagList: form.tagList.map((item) => (tag === item ? { ...item, [key]: value } : item)) });
  }, [form]);

  const onSelectAttachmentFile = useCallback((attachment: DocumentUpdateForm['attachmentFileList'][number], file: File) => {
    setForm({
      ...form,
      attachmentFileList: form.attachmentFileList.map((item) => (attachment === item ? {
        ...item, file, fileName: file?.name, fileSize: file?.size,
      } : item)),
    });
  }, [form]);

  const setDocumentAttachmentValue = useCallback((
    attachment: DocumentUpdateForm['attachmentFileList'][number],
    key: keyof DocumentUpdateForm['attachmentFileList'][number],
    value: DocumentUpdateForm['attachmentFileList'][number][keyof DocumentUpdateForm['attachmentFileList'][number]],
  ) => {
    setForm({ ...form, attachmentFileList: form.attachmentFileList.map((item) => (attachment === item ? { ...item, [key]: value } : item)) });
  }, [form]);

  const removeAttachmentFile = useCallback((attachment: DocumentUpdateForm['attachmentFileList'][number]) => {
    const updatedAttachmentFileList = form.attachmentFileList.map((item) => {
      if (item.id && item.id === attachment.id) {
        return { ...item, deleted: true };
      }
      return item;
    }).filter((item) => item.id || item !== attachment);

    setForm({ ...form, attachmentFileList: updatedAttachmentFileList });
  }, [form]);

  const downloadAttachmentFile = useCallback(async (attachFile: { id?: number, fileName?: string }, pdfName?: string) => {
    if (documentVersion) {
      const attachmentFile = await documentAttachmentDownload.request({ attachmentId: attachFile.id as number });
      downloadFile(attachmentFile, pdfName);
    }
  }, [createLog, documentAttachmentDownload, documentVersion, downloadFile]);

  const onDownloadClick = useCallback(() => {
    if (form.file) {
      if (documentVersion) createDownloadLog(documentVersion.dataId, 0);

      downloadFile(form.file, form.fileName);
    }
  }, [createLog, downloadFile, form]);

  const onDeleteRegisterClick = useCallback(async () => {
    if (!documentRef || !documentVersion) return;
    await openAlertModal('文書を削除してよろしいですか？');
    try {
      await documentDelete.request({ id: documentRef.id });
      onRemoveDocument(documentRef);
      onClose(true);
    } catch (e) {
      await openMessageModal((e as ApiError)?.message);
    }
  }, [documentDelete, documentRef, documentVersion, onClose, onRemoveDocument, openAlertModal, openMessageModal]);

  const onDeleteRevisionClick = useCallback(async () => {
    if (!documentRef || !documentVersion) return;
    await openAlertModal('表示中の版情報を削除してよろしいですか？');
    try {
      await documentDeleteRevision.request({ id: documentRef.id, versionId: documentVersion.versionNo });
      onRemoveDocumentVersion(documentVersion);
    } catch (e) {
      await openMessageModal((e as ApiError)?.message);
    }
  }, [documentDeleteRevision, documentRef, documentVersion, onRemoveDocumentVersion, openAlertModal, openMessageModal]);

  const onFileSelected = useCallback((file: File | undefined) => {
    setFileHasChanged(true);
    setForm({
      ...form, file, fileName: file?.name, fileSize: file?.size, fileId: undefined,
    });
  }, [form]);

  const documentTypeOptions = useMemo(() => {
    const result: DropDownMenuItem<DocumentClassificationOption>[] = [];
    documentClassifications.forEach((documentClassification) => {
      documentClassification.types.forEach((type) => {
        result.push({
          text: `${documentClassification.name}: ${type.documentTypeName}`,
          value: { documentClassification, type },
        });
      });
    });

    return result;
  }, [documentClassifications]);

  const selectedClassification = useMemo((): DropDownMenuItem<DocumentClassificationOption> | undefined => documentTypeOptions.find((item) => item.value.type.documentTypeId === form.typeId && item.value.documentClassification.id === form.classification), [documentTypeOptions, form.classification, form.typeId]);

  const onDocumentPrintedTypeSelected = useCallback((e: 'ELECTRONIC' | 'PAPER') => {
    if (!selectedClassification?.text.startsWith('契約書')) {
      setForm({ ...form, electronicFlg: e === 'ELECTRONIC' ? 1 : 0, paperFlg: e === 'PAPER' ? 1 : 0 });
    } else if (documentPrintedTypeSelected === 'PAPER' && e === 'ELECTRONIC') {
      setForm({ ...form, electronicFlg: 1, paperFlg: 0 });
    } else {
      setForm({ ...form, electronicFlg: e === 'ELECTRONIC' ? 1 : 0 });
    }
    setDocumentPrintedTypeSelected(e);
  }, [documentPrintedTypeSelected, form, selectedClassification]);

  const onSaveDocument = async (saveType: string) => {
    const updatedForm = {
      ...form,
      attachmentFileList: form.attachmentFileList.filter((item) => !item.deleted),
    };

    if (!selectedClassification?.text.startsWith('契約書')) {
      delete updatedForm.electronicFlg;
      delete updatedForm.paperFlg;
    }
    try {
      if (saveType === 'revision') {
        await documentRevision.request(updatedForm);
      }
      if (saveType === 'update') {
        await documentUpdate.request(updatedForm);
      }
      onUpdateVersion(updatedForm);
      onDocumentUpdated();
      onClose(true);
    } catch (e) {
      await openMessageModal((e as ApiError)?.message);
    }
  };

  const removeSameDocuments = useCallback((documents: Document[]) => documents.filter((document) => !form.relationalDocumentList?.some((item) => Number(item.id) === Number(document.id)) && Number(document.id) !== Number(form.id)), [form]);

  const handleRelatedDocuments = useCallback((documents: Document[]) => {
    const filteredDocuments = removeSameDocuments(documents);

    const documentsToFormFormat = filteredDocuments.map((document) => ({
      ccControlNumber: document.ccControlNumber,
      id: document.id,
      name: document.name,
      type: document.typeName as string,
      duplicateTags: [],
    }));
    const newRelatedDocumentsForm = [...form.relationalDocumentList as [], ...documentsToFormFormat];
    setForm({ ...form, relationalDocumentList: newRelatedDocumentsForm });
    setChoosingRelatedDocument(false);
    onCancelAddingRelatedDocuments();
  }, [form, onCancelAddingRelatedDocuments, removeSameDocuments]);

  const removeRelatedDocument = useCallback((document: RelationalDocument) => {
    setForm({ ...form, relationalDocumentList: form?.relationalDocumentList?.filter((item) => item !== document) });
  }, [form]);

  const onRegister = async () => {
    const updatedForm = {
      ...form,
      attachmentFileList: form.attachmentFileList.filter((item) => !item.deleted),
    };

    const requiredTagNotFilled = updatedForm.tagList?.some((tag) => tag.required && tag.value === '');
    if (requiredTagNotFilled) {
      openMessageModal('全ての必須項目を入力してください。');
      return;
    }

    if (updatedForm.file && (editingVersionState?.fileName !== updatedForm.fileName || editingVersionState?.fileSize !== updatedForm.fileSize)) {
      setOperationModal(true);
    } else {
      try {
        await documentUpdate.request(updatedForm);
        onUpdateVersion(updatedForm);
        onDocumentUpdated();
        onClose(true);
      } catch (e) {
        await openMessageModal((e as ApiError)?.message);
      }
    }
  };

  useEffect(() => {
    if (documentRef) {
      setForm({
        id: documentRef.id,
        attachmentFileList: [],
        classification: 0,
        file: undefined,
        fileName: '',
        fileSize: 0,
        fileId: undefined,
        memo: '',
        name: '',
        relationalDocumentList: [],
        tagList: [],
        typeId: 0,
        typeName: '',
        barcodePrinting: documentRef.barcodePrinting,
        electronicFlg: documentRef.electronicFlg,
        paperFlg: documentRef.paperFlg,
      });
      if (documentRef.documents.length) {
        const editingVersion = documentRef.documents.find((doc) => doc.versionNo === editingVersionNo);
        setEditingVersionState(editingVersion);
        if (editingVersion) updateVersion(editingVersion);
      }
    }
  }, [documentRef]);

  useEffect(() => {
    fileAttachmentRefs.current = fileAttachmentRefs.current.slice(0, form.attachmentFileList.length);
  }, [form.attachmentFileList]);

  const describeDocumentType = (docTypeId: number) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < documentClassifications.length; i++) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < documentClassifications[i].types.length; j++) {
        const docType = documentClassifications[i].types[j];
        if (docType.documentTypeId === docTypeId) {
          return docType.documentTypeName;
        }
      }
    }
    return '';
  };

  const handlePreviewPdf = () => {
    if (documentVersion) {
      createDownloadLog(documentVersion.dataId, 1);
    }
    setPdfForModal(form.file ? form.file : null);
  };

  const handleAddAttachmentFile = () => {
    inputAttachmentFile.current?.click();
    if (inputAttachmentFile.current) {
      inputAttachmentFile.current.value = '';
    }
  };

  const onAttachmentFileSelected = useCallback((value: FileList | null) => {
    setForm({
      ...form,
      attachmentFileList: [...form.attachmentFileList, {
        file: value?.item(0) as File, fileName: value?.item(0)?.name, fileSize: value?.item(0)?.size,
      }],
    });
  }, [form]);

  const onDeleteFile = () => {
    setFileHasChanged(true);
    setForm({
      ...form, file: undefined, fileName: '', fileSize: 0, fileId: undefined,
    });
  };

  return (
    <div className={styles.bodySection}>
      <div className={mainStyles['d-flex']}>
        <div
          className={[mainStyles['flex-1'], mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['justify-content-end']].join(' ')}
        >
          <SelectInput
            className={[mainStyles['mr-4']].join(' ')}
            value={documentVersion}
            options={documentRef?.documents?.sort((a, b) => b.versionNo - a.versionNo).map((item) => ({ text: `第${item.versionNo}版`, value: item })) || []}
            onChange={updateVersion}
            style={{ width: '170px' }}
          />
          <img
            className={[styles.topButtonsImg].join(' ')}
            src="/images/Close-333333.svg"
            alt=""
            onClick={() => onClose(true)}
          />
        </div>
      </div>
      <div className={styles.bodySectionMain}>

        <div className={styles.pdfContainer}>
          <PdfPreview className={styles.imagesContainerSection} pdfFile={form.file || undefined} pageHeight={250} />
          {form.file && (
            <img
              className={styles.arrowImg}
              src="/images/provisory-images/arrow-up-right.svg"
              alt=""
              onClick={handlePreviewPdf}
            />
          )}
        </div>
        <div className={styles.formContainer}>
          <div className={[mainStyles['d-flex'], mainStyles['mt-20px']].join(' ')}>
            <div className={styles.label}>
              文書名
            </div>
            <div>
              <InputAutoSize
                className={styles.formInput}
                value={form.name}
                onChange={(name) => setForm({ ...form, name })}
              />
            </div>
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')}>
            <div className={styles.label}>
              電子データ
            </div>
            <div className={mainStyles['flex-1']}>
              <InputAutoSize
                className={styles.formInput}
                value={form.file ? form.file.name : form.fileName}
                onChange={(fileName) => {
                  if (form.file) return;
                  setForm({ ...form, fileName });
                }}
              />
            </div>
            <div className={[mainStyles['d-flex'], mainStyles['flex-4'], mainStyles['ml-4']].join(' ')}>
              <input
                type="file"
                ref={fileSelectorRef}
                style={{ display: 'none' }}
                accept="application/pdf"
                onChange={(e) => onFileSelected(e.target.files ? e.target.files[0] : undefined)}
              />
              <Button className={[mainStyles['small-button']].join(' ')} color="lighterGray" size="smaller" onClick={() => fileSelectorRef.current?.click()}>
                <img src="/images/Icon-metro-attachment.svg" alt="" />
              </Button>
              {(form.file) && (
              <>
                <Button
                  className={[mainStyles['small-button'], mainStyles['ml-10']].join(' ')}
                  color="lightPrimary"
                  size="smaller"
                  onClick={onDownloadClick}
                >
                  <img src="/images/Icon-metro-file-download.svg" alt="" />
                </Button>
                <Button
                  className={[mainStyles['small-button'], mainStyles['ml-10']].join(' ')}
                  color="lightPrimary"
                  size="smaller"
                  onClick={onDeleteFile}
                >
                  <img src="/images/Icon-feather-trash-2.svg" alt="" />
                </Button>
              </>
              )}
            </div>
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['mt-15px']].join(' ')}>
            <div className={styles.label}>
              文書種類
            </div>
            <div>
              <SelectInput
                className={styles.formSelect}
                value={documentTypeOptions.find((item) => item.value.type.documentTypeId === form.typeId && item.value.documentClassification.id === form.classification)?.value}
                options={documentTypeOptions}
                onChange={updateClassificationType}
              />
            </div>
          </div>
          {selectedClassification?.text.startsWith('契約書') && (
          <>
            <div
              className={[mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['mt-15px']].join(' ')}
            >
              <div className={styles.label}>契約種類</div>
              <div
                className={[mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['w-100'], mainStyles['ml-4'], styles.text].join(' ')}
              >
                <RadioGroupInput value={documentPrintedTypeSelected} onChange={onDocumentPrintedTypeSelected}>
                  <RadioGroupInput.RadioInput value="ELECTRONIC">電子契約</RadioGroupInput.RadioInput>
                  <RadioGroupInput.RadioInput value="PAPER">書面契約</RadioGroupInput.RadioInput>
                </RadioGroupInput>
              </div>
            </div>
            <div
              className={[mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['mt-15px']].join(' ')}
            >
              <div className={styles.label}>書面有無</div>
              <div
                className={[mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['w-100'], mainStyles['ml-4'], styles.text].join(' ')}
              >
                { documentPrintedTypeSelected === 'ELECTRONIC' ? (
                  <RadioGroupInput value="" onChange={() => setForm({ ...form, paperFlg: 0 })}>
                    <RadioGroupInput.RadioInput disabled value="1">有り</RadioGroupInput.RadioInput>
                    <RadioGroupInput.RadioInput disabled value="" style={{ marginLeft: '67px' }}>無し</RadioGroupInput.RadioInput>
                  </RadioGroupInput>
                ) : (
                  <RadioGroupInput
                    value={form.paperFlg === 1 ? '1' : '0'}
                    onChange={(value) => setForm(
                      {
                        ...form,
                        paperFlg: value === '1' ? 1 : 0,
                      },
                    )}
                  >
                    <RadioGroupInput.RadioInput value="1">有り</RadioGroupInput.RadioInput>
                    <RadioGroupInput.RadioInput value="0" style={{ marginLeft: '67px' }}>無し</RadioGroupInput.RadioInput>
                  </RadioGroupInput>
                )}
              </div>
            </div>
            <div className={`${mainStyles['d-flex']} ${mainStyles['mt-15px']}`}>
              <div className={styles.label}>
                <div>
                  バーコード
                </div>
                {' '}
                印字欄
              </div>
              <div>
                <InputAutoSize
                  disabled={(documentRef?.barcodeChangeable !== 1 || form.paperFlg === 0)}
                  className={`${mainStyles.input} ${styles.width280px}`}
                  value={form.barcodePrinting}
                  onChange={(barcodePrinting) => setForm({ ...form, barcodePrinting })}
                />
                <div className={mainStyles['text-danger']}>
                  ※入力した値が入庫時に貼り付けるバーコードに印字されます。
                  入庫後の値の変更はできません
                </div>
              </div>
            </div>
          </>
          )}
          <div className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')}>
            <div className={styles.label}>
              タグ
            </div>
            <div className={[styles.tagTextContent, styles.text].join(' ')}>
              {form.tagList?.map((tag) => (
                <div className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')} key={tag.tagLabel}>
                  <div
                    className={[styles.text, mainStyles.expandDiv].join(' ')}
                    style={{ width: '170px', minWidth: '170px', marginRight: '10px' }}
                  >
                    {tag.tagLabel}
                  </div>
                  <div style={{ width: '20px' }}>
                    { tag.required && (
                    <div className={` ${mainStyles['text-danger']} ${mainStyles['d-flex']} ${mainStyles['justify-content-center']}`} style={{ marginRight: '10px' }}>
                      ※
                    </div>
                    )}
                  </div>
                  <TagInput
                    tag={tag}
                    onChange={(value) => setDocumentVersionTagValue(tag, 'value', value)}
                    className={styles.width280px}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')}>
            <div className={styles.label}>
              メモ
            </div>
            <div style={{ width: 'calc(100% - 110px)' }}>
              <textarea
                className={styles.textArea}
                value={form.memo || ''}
                onChange={(e) => setForm({ ...form, memo: e.target.value })}
              />
            </div>
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')}>
            <div className={styles.label}>
              関連文書
            </div>
            <div style={{ width: '100%' }}>
              { form?.relationalDocumentList?.map((relationalDocument) => (
                <div className={styles.relationalDocumentDetail} key={uuid()}>
                  <div style={{ width: '200px' }} className={[styles.text, mainStyles.expandDiv].join(' ')}>
                    文書ID:
                    {' '}
                    {relationalDocument.ccControlNumber}
                  </div>
                  <div style={{ width: '200px' }} className={mainStyles.expandDiv}>
                    文書名:
                    {' '}
                    {relationalDocument.name}
                  </div>
                  <div style={{ width: '200px', marginLeft: '15px' }} className={mainStyles.expandDiv}>
                    文書種類:
                    {' '}
                    {describeDocumentType(+(relationalDocument.type ?? '0'))}
                  </div>
                  <button
                    type="button"
                    className={`${mainStyles.button} ${mainStyles['btn-primary-light']} ${mainStyles['small-button']} ${mainStyles['d-flex']} ${mainStyles['align-items-center']} ${mainStyles['justify-content-center']}`}
                    onClick={() => { removeRelatedDocument(relationalDocument); }}
                  >
                    <img src="/images/Icon-feather-trash-2.svg" alt="" />
                  </button>
                </div>
              ))}
              <Button
                color="lighterGray"
                size="smaller"
                onClick={() => {
                  setChoosingRelatedDocument(true);
                }}
              >
                文書参照
              </Button>
            </div>
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['mt-15px']].join(' ')}>
            <div className={styles.label}>
              添付ファイル
            </div>
            <div style={{ width: '100%' }}>
              {form.attachmentFileList.map((attachment) => (
                <div className={[mainStyles['d-flex'], mainStyles['mb-15px']].join(' ')} key={uuid()}>
                  {!attachment.id ? (
                    <div>
                      <img src="/images/CloseMidGray.svg" alt="" style={{ marginLeft: '-25px', width: '25px', cursor: 'pointer' }} onClick={() => removeAttachmentFile(attachment)} />
                    </div>
                  ) : ''}
                  <div className={mainStyles['flex-1']}>
                    <TextareaAutosize
                      disabled
                      style={{ minHeight: '25px', paddingTop: '4px' }}
                      className={[mainStyles.textareaAutosize, styles.formInput, styles.disabledColor, attachment.deleted ? styles.deletedTextStyle : ''].join(' ')}
                      value={attachment.fileName}
                      placeholder="xxxxx.txt"
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDocumentAttachmentValue(attachment, 'fileName', e.target.value)}
                    />
                  </div>
                  <div className={[mainStyles['d-flex'], mainStyles['flex-4'], mainStyles['ml-4']].join(' ')}>
                    {!attachment.id ? (
                      <FileInputButton icon onSelect={(file) => file && onSelectAttachmentFile(attachment, file as File)} />
                    ) : ''}
                    {attachment.id ? (
                      <>
                        <Button
                          className={[mainStyles['small-button']].join(' ')}
                          color="lightPrimary"
                          size="smaller"
                          onClick={() => attachment && downloadAttachmentFile(attachment, attachment.fileName)}
                        >
                          <img src="/images/Icon-metro-file-download.svg" alt="" />
                        </Button>
                        {!attachment.deleted && (
                          <Button className={[mainStyles['small-button'], mainStyles['ml-10']].join(' ')} color="lightPrimary" size="smaller" onClick={() => removeAttachmentFile(attachment)}>
                            <img src="/images/Icon-feather-trash-2.svg" alt="" />
                          </Button>
                        )}
                      </>
                    ) : ''}
                  </div>
                </div>
              ))}
              <Button
                color="lighterGray"
                size="smaller"
                style={{ width: 180 }}
                onClick={handleAddAttachmentFile}
              >
                添付ファイルを追加
              </Button>
            </div>
          </div>

          <div className={[mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['mt-15px']].join(' ')}>
            <div className={styles.label}>
              フォルダ
            </div>
            <div className={styles.text}>
              {documentRef?.folder.name}
            </div>
          </div>
          <div className={[mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['mt-15px']].join(' ')}>
            <div className={styles.label}>
              ステータス
            </div>
            <div className={[mainStyles['flex-1'], styles.text].join(' ')}>
              { documentRef && documentRef.warehouseStatus }
            </div>
          </div>
        </div>
        <div className={styles.footerButton}>
          {documentRef && hasFolderPermission(documentRef.folder, ControlCode.Delete) && (
            <div>
              <Button
                color="error"
                onClick={onDeleteRegisterClick}
                size="smaller"
                loading={documentDelete.loading}
                style={{ marginRight: 10 }}
              >
                文書を削除
              </Button>
              {
              (documentRef?.documents.length || 0) > 1 && (
                <Button color="error" onClick={onDeleteRevisionClick} size="smaller" className={styles.deleteRevisionButtonWidth} loading={documentDeleteRevision.loading} disabled={isDeletionDisabled}>
                  表示中の版情報を削除
                </Button>
              )
              }
            </div>
          )}
          <div />
          {documentRef && hasFolderPermission(documentRef.folder, ControlCode.Write) && (
            <Button
              isRequestingButton
              loading={documentUpdate.loading || documentRevision.loading}
              className={[mainStyles['btn-success']].join(' ')}
              size="smaller"
              onClick={onRegister}
            >
              登録
            </Button>
          )}
        </div>
        <BaseModal open={operationModal} style={{ width: '530px' }}>
          <div className={[styles.alertMessage].join(' ')}>
            編集した文書を改版しますか？
            <br />
            改版せずに内容を差し替えますか？
          </div>
          <div className={style.alertButtons} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              loading={documentUpdate.loading}
              size="smaller"
              onClick={() => {
                onSaveDocument('update');
                setOperationModal(false);
              }}
            >
              差し替える
            </Button>
            <Button
              loading={documentRevision.loading}
              size="smaller"
              onClick={() => {
                onSaveDocument('revision');
                setOperationModal(false);
              }}
            >
              改版する
            </Button>
            <Button
              color="lightGray"
              size="smaller"
              onClick={() => setOperationModal(false)}
            >
              キャンセル
            </Button>
          </div>
        </BaseModal>
        { (form.file && pdfForModal) && (
        <PdfPreviewModal
          open
          onClose={() => setPdfForModal(null)}
          pdfFile={pdfForModal}
          fileName={documentVersion?.fileName || ''}
          fileId={documentVersion?.dataId || 0}
          showOcrSearchPreview={documentVersion?.documentFileOcrFlg === true}
          documentVersionNo={documentVersion?.versionNo}
          documentId={documentRef?.id}
        />
        )}
        {choosingRelatedDocument && (
        <ChooseRelatedDocument
          onChooseRelatedDocuments={(docs) => {
            handleRelatedDocuments(docs);
          }}
          onCancelAddingRelatedDocuments={() => {
            onCancelAddingRelatedDocuments();
            setChoosingRelatedDocument(false);
          }}
        />
        )}
      </div>
      <input
        type="file"
        id="file"
        ref={inputAttachmentFile}
        style={{ display: 'none' }}
        onChange={(e) => onAttachmentFileSelected(e.target.files)}
      />
    </div>

  );
}

export default DocDetailEdit;
