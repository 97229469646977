import axios from 'axios';
import { DateTime } from 'luxon';

export interface SupplierListSearchForm {
  customerName?: string,
  tenantCode?: string,
  ownerName?: string,
  contractName?: string,
  email?: string,
  userName?: string,
  existFirstUser?: boolean,
}

export interface Supplier {
  customerId: number,
  customerCd: string,
  customerName: string,
  tenantCode: string,
  existFirstUser: boolean,
  ownerCd: string,
  ownerName: string,
  contractGroupCode: string,
  contractGroupName: string,
  contractCode: string,
  contractName: string,
  firewallUseFlg: number,
  blockingUseFlg: number,
  ocrUseFlg: number,
  registerDate: Date,
  registerUser: string,
  updateDate: Date,
  updateUser: string,
}

export interface SupplierDetails {
  customerId: string,
  customerCd: string,
  customerName: string,
  tenantCode: string,
  existFirstUser: boolean,
  ownerCd: string,
  ownerName: string,
  contractGroupCode: string,
  contractGroupName: string,
  contractCode: string,
  contractName: string,
  registerDate: Date,
  registerUser: string,
  updateDate: Date,
  updateUser: string,
  hasSsoSupport: boolean,
  ocrUseFlg: boolean,
  passwordUpdate: number,
}

export interface CreateFirstUserForm {
  customerId: number,
  tenantCode: string,
  userName: string,
  email: string,
  password: string,
}

export interface ControlList {
  controlCode: string,
}

export interface SupplierPresetTemplate {
  presetName: string,
  presetExplanation: string,
  controlList: ControlList[],
}

export enum OrganizationAuthorityType {
  SSO = 'SSO',
  EmailAndPassword = 'EmailAndPassword',
}

export interface RegisterAuthorityForm {
  organizationId: number,
  hasSsoSupport: boolean,
  hasOcrSupport: boolean,
  controlList: ControlList[],
}

export default {
  /**
   * 仕入先の一覧を取得する関数
   *
   * @param form - 仕入先検索フォーム
   * @returns - 仕入先の一覧
   */
  async search(form: SupplierListSearchForm): Promise<Supplier[]> {
    const res = await axios.get('/Organization/search', {
      params: {
        customerName: form.customerName || null,
        tenantCode: form.tenantCode || null,
        ownerName: form.ownerName || null,
        contractName: form.contractName || null,
        email: form.email || null,
        userName: form.userName || null,
        existFirstUser: form.existFirstUser === false ? form.existFirstUser : undefined,
      },
    });
    const { data } = res;
    return data.customerList.map((item: {
      customerId: number,
      customerCd: string,
      customerName: string,
      tenantCode: string,
      existFirstUser: boolean,
      ownerCd: string,
      ownerName: string,
      contractGroupCode: string,
      contractGroupName: string,
      contractCode: string,
      contractName: string,
      firewallUseFlg: number,
      blockingUseFlg: number,
      ocrUseFlg: number,
      registerDate: string,
      registerUser: string,
      updateDate: string,
      updateUser: string,
    }): Supplier => ({
      customerId: item.customerId,
      customerCd: item.customerCd,
      customerName: item.customerName,
      tenantCode: item.tenantCode,
      existFirstUser: item.existFirstUser,
      ownerCd: item.ownerCd,
      ownerName: item.ownerName,
      contractGroupCode: item.contractGroupCode,
      contractGroupName: item.contractGroupName,
      contractCode: item.contractCode,
      contractName: item.contractName,
      firewallUseFlg: item.firewallUseFlg,
      blockingUseFlg: item.blockingUseFlg,
      ocrUseFlg: item.ocrUseFlg,
      registerDate: DateTime.fromISO(item.registerDate).toJSDate(),
      registerUser: item.registerUser,
      updateDate: DateTime.fromISO(item.updateDate).toJSDate(),
      updateUser: item.updateUser,
    }));
  },
  /**
   * 仕入先詳細情報を取得する関数
   *
   * @param form - 仕入先ID
   * @returns - 仕入先詳細情報
   */
  async getSupplierDetails(form: { id: number }): Promise<SupplierDetails> {
    const res = await axios.get('/Organization/detail', {
      params: { customerId: form.id },
    });
    const { data } = res;
    return {
      customerId: data.customerId,
      customerCd: data.customerCd,
      customerName: data.customerName,
      tenantCode: data.tenantCode,
      existFirstUser: data.existFirstUser,
      ownerCd: data.ownerCd,
      ownerName: data.ownerName,
      contractGroupCode: data.contractGroupCode,
      contractGroupName: data.contractGroupName,
      contractCode: data.contractCode,
      contractName: data.contractName,
      hasSsoSupport: data.hasSsoSupport,
      ocrUseFlg: Boolean(data.ocrUseFlg),
      registerDate: DateTime.fromISO(data.registerDate).toJSDate(),
      registerUser: data.registerUser,
      updateDate: DateTime.fromISO(data.updateDate).toJSDate(),
      updateUser: data.updateUser,
      passwordUpdate: data.passwordUpdate,
    };
  },

  /**
   * 仕入先の最初のユーザーを作成する関数
   *
   * @param form - 最初のユーザー作成フォーム
   * @returns - 空のプロミス
   */
  async createFirstUser(form: CreateFirstUserForm): Promise<void> {
    const res = await axios.post('/Organization/createFirstUser', form);
    const { data } = res;
    return data;
  },
  /**
   * 仕入先のプリセットテンプレート一覧を取得する関数
   *
   * @param form - 仕入先ID
   * @returns - 仕入先のプリセットテンプレート一覧
   */
  async getSupplierPresetTemplateList(form: { organizationId: number }): Promise<SupplierPresetTemplate[]> {
    const res = await axios.get('/Organization/getPreset', {
      params: { organizationId: form.organizationId },
    });
    const { data } = res;
    return data.presetList.map((item: {
      presetName: string,
      presetExplanation: string,
      controlList: {
        controlCode: string,
      }[]
    }):SupplierPresetTemplate => ({
      presetName: item.presetName,
      presetExplanation: item.presetExplanation,
      controlList: item.controlList,
    }
    ));
  },
  /**
   * 仕入先の権限一覧を取得する関数
   *
   * @param form - 仕入先ID
   * @returns - 仕入先の権限一覧
   */
  async getAuthorityList(form: { organizationId: number }): Promise<ControlList[]> {
    const res = await axios.get('/Organization/getAuthority', {
      params: { organizationId: form.organizationId },
    });
    const { data } = res;
    return data.controlList.map((item: {
      controlCode: string,
    }): ControlList => ({
      controlCode: item.controlCode,
    }));
  },
  /**
   * 権限を登録する関数
   *
   * @param form - 権限登録フォーム
   * @returns - 空のプロミス
   */
  async registerAuthority(form: RegisterAuthorityForm): Promise<void> {
    const res = await axios.post('/Organization/registerAuthority', form);
    const { data } = res;
    return data;
  },

};
