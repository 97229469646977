import { useLocation, useNavigate } from 'react-router-dom';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import MainFrame from '../../components/MainFrame/mainFrame';
import usePageTitle from '../../hooks/title.hook';
import styles from './supplierPermissionScreenPage.module.css';
import mainStyles from '../main.module.css';
import Button from '../../components/Button/button';
import routes from '../../utils/routes';
import {
  useGetSupplierAuthorityApi,
  useGetSupplierPresetTemplateApi,
  useRegisterAuthorityApi,
} from '../../hooks/api/supplier.hook';
import {
  ControlList,
  RegisterAuthorityForm,
  SupplierPresetTemplate,
} from '../../services/http/supplier.api';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import { DropDownMenuItem } from '../../components/DropdownMenu';
import AlertModal, {
  alertModalInitialState, CloseAlertModal, closeAlertModalWithJSXState, CloseAlertModalTextType,
} from '../../components/AlertModal';
import { presetListControlItems } from '../permissionTemplateCreationPage/permissionTemplateCreationPage';
import Breadcrumb from '../../components/Breadcrumb';
import { ApiError } from '../../services/http';
import { useMessageModal } from '../../hooks/modal.hook';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import { useCreateLogApi } from '../../hooks/api/log.hook';
import { LogControlName, LogFormName } from '../../utils/log.utils';
import Checkbox from '../../components/Checkbox';

interface CustomerInfoProps {
  customerId: number;
  customerCd: string;
  customerName: string;
  customerTenantCode: string;
}

/**
 * 取引先権限確認・更新のコンポーネント
 */
function Component() {
  usePageTitle('取引先権限確認・更新');

  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    customerId, customerCd, customerName, customerTenantCode,
  } = state as CustomerInfoProps;

  const openMessageModal = useMessageModal();

  // States
  const [supplierPresetTemplateList, setSupplierPresetTemplateList] = useState<SupplierPresetTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<SupplierPresetTemplate | null>(null);
  const [presetExplanation, setPresetExplanation] = useState<string>('');
  const [alertModal, setAlertModal] = useState(alertModalInitialState);
  const [closeAlertModal, setCloseAlertModal] = useState(closeAlertModalWithJSXState);
  const [itemsCheckbox, setItemsCheckbox] = useState<ControlList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // APIs
  const getSupplierPresetTemplateList = useGetSupplierPresetTemplateApi();
  const getSupplierAuthority = useGetSupplierAuthorityApi();
  const registerAuthorityApi = useRegisterAuthorityApi(customerId);
  const { request: createLog } = useCreateLogApi();

  // Effects
  useEffect(() => {
    try {
      getSupplierPresetTemplateList.request({ organizationId: customerId }).then((res) => setSupplierPresetTemplateList(res));
      getSupplierAuthority.request({ organizationId: customerId }).then((res) => {
        setItemsCheckbox(res);
      });
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, []);

  useEffectOnce(() => {
    createLog(LogFormName.SupplierPermissionScreen, LogControlName.Show);
  });

  // Methods
  const handleSelectedTemplate = useCallback((e: SupplierPresetTemplate) => {
    setSelectedTemplate(e);
    setPresetExplanation(e.presetExplanation);
  }, []);

  const reflectTemplateConfig = useCallback(() => {
    if (selectedTemplate) {
      setItemsCheckbox(selectedTemplate.controlList);
    }
  }, [selectedTemplate]);

  const checkAllCheckboxes = useCallback(() => {
    const all = presetListControlItems.map((d) => d.children).flat().map((d) => d?.id ?? '').map((d) => ({ controlCode: d }));
    setItemsCheckbox(all);
  }, []);

  const registerAuthority = useCallback(() => {
    setAlertModal({
      text: '保存してよろしいですか？',
      open: true,
      onConfirm: async () => {
        setLoading(true);
        try {
          createLog(LogFormName.SupplierPermissionScreen, LogControlName.Create);

          const controlList: ControlList[] = itemsCheckbox;
          const form: RegisterAuthorityForm = {
            organizationId: customerId,
            hasSsoSupport: registerAuthorityApi.form.hasSsoSupport.value,
            hasOcrSupport: registerAuthorityApi.form.hasOcrSupport.value,
            controlList,
          };
          await registerAuthorityApi.create(form);
        } catch (e) {
          setAlertModal({ ...alertModal, open: false });
          openMessageModal((e as Error)?.message);
          return;
        } finally {
          setLoading(false);
        }
        setAlertModal({ ...alertModal, open: false });
        setCloseAlertModal({
          ...closeAlertModal,
          open: true,
          text: '保存が完了しました',
          onCancel: () => {
            setCloseAlertModal({ ...closeAlertModal, open: false });
            navigate(routes.supplierListScreen);
          },
        });
      },
      onCancel: () => {
        setAlertModal({
          ...alertModal, open: false,
        });
      },
    });
  }, [alertModal, closeAlertModal, customerId, itemsCheckbox, navigate, registerAuthorityApi]);

  const handleCheckboxChange = useCallback((checkboxItem: string) => {
    if (itemsCheckbox.some((item) => item.controlCode === checkboxItem)) {
      setItemsCheckbox(itemsCheckbox.filter((item) => item.controlCode !== checkboxItem));
    } else {
      setItemsCheckbox([...itemsCheckbox, { controlCode: checkboxItem }]);
    }
  }, [itemsCheckbox]);

  // Memo
  const templateOptions = useMemo<DropDownMenuItem<SupplierPresetTemplate>[]>(() => supplierPresetTemplateList.map((template) => ({
    value: template,
    text: template.presetName,
  })), [supplierPresetTemplateList]);

  useEffect(() => {
    if (registerAuthorityApi.supplierGetDetails.data && !registerAuthorityApi.form.hasSsoSupport.value) {
      openMessageModal('SAML認証の権限をOFFにすると、保存を押した時点で対象の取引先のシングルサインオンが無効になります。\n 間違いがないことを改めて確認の上保存をするようご注意ください。');
    }
  }, [registerAuthorityApi.form.hasSsoSupport.value]);

  useEffect(() => {
    if (registerAuthorityApi.supplierGetDetails.data && !registerAuthorityApi.form.hasOcrSupport.value) {
      setCloseAlertModal({
        ...closeAlertModal,
        open: true,
        text: (
          <div style={{ textAlign: 'left' }}>
            <div className={styles.bold}>全文検索をOFFにすると、以下の点にご注意ください</div>
            <br />
            <div style={{ textAlign: 'left' }}>
              ・保存を押した時点から、対象の取引に対する全文検索が
              <div style={{ marginLeft: 14 }}>利用できなくなります。</div>
              ・OFFの期間中に登録された文書は、
              <div style={{ marginLeft: 14 }}>再度ONにしても検索対象には含まれません。</div>
            </div>
            <br />
            <div className={styles.bold}>保存する前に、設定に誤りがないか必ずご確認ください。</div>
          </div>
        ) as CloseAlertModalTextType,
        onCancel: () => {
          setCloseAlertModal({ ...closeAlertModal, open: false });
        },
        closeButtonText: 'OK',
      });
    }
  }, [registerAuthorityApi.form.hasOcrSupport.value]);

  return (
    <MainFrame
      borderBox
      body={(
        <div className={styles.mainframe}>
          <Breadcrumb crumbs={[{ label: document.title }]} />
          <div className={[styles['mainframe-header'], mainStyles['pl-3']].join(' ')}>
            権限設定
          </div>
          <div className={styles['mainframe-body']}>
            <div className={styles['mainframe-body-content']}>
              <div className={[styles.label, mainStyles['mt-10']].join(' ')}>
                テンプレートを選択
              </div>
              <div className={[mainStyles['d-flex'], mainStyles['mb-20px']].join(' ')}>
                <AutoCompleteInput
                  value={selectedTemplate?.presetName}
                  options={templateOptions}
                  onSelect={handleSelectedTemplate}
                  deselectOption
                  style={{ maxWidth: '58%' }}
                  menuStyle={{ width: '100%' }}
                  dropDownItemStyle={{ maxWidth: 'unset' }}
                />
                <Button
                  size="smaller"
                  color="lighterGray"
                  className={[mainStyles['ml-20px']].join(' ')}
                  onClick={reflectTemplateConfig}
                >
                  テンプレート内容を反映
                </Button>
              </div>
              <div className={styles.label}>
                説明
              </div>
              <div className={[styles.text, styles['item-width'], mainStyles['mb-20px']].join(' ')}>
                {presetExplanation}
              </div>
              <div className={[mainStyles['mt-10px'], mainStyles['ml-10px']].join(' ')}>
                <button
                  type="button"
                  className={[mainStyles.button, mainStyles['btn-gray'], styles['button-large'], mainStyles['mr-10']].join(' ')}
                  onClick={checkAllCheckboxes}
                >
                  全ての権限をチェック
                </button>
                <button
                  type="button"
                  className={[mainStyles.button, mainStyles['btn-gray'], styles['button-large'], mainStyles['mb-20px']].join(' ')}
                  onClick={() => setItemsCheckbox([])}
                >
                  全てのチェックを外す
                </button>
              </div>
              {presetListControlItems.map((item) => (
                <div key={item.id}>
                  {item.children.map((_item, key) => (
                    <div
                      key={_item.id}
                      className={[styles.text, mainStyles['d-flex'], key > 0 ? mainStyles['mt-10'] : ''].join(' ')}
                    >
                      <input
                        type="checkbox"
                        checked={itemsCheckbox.some((i) => i.controlCode === _item.id)}
                        onChange={() => handleCheckboxChange(_item.id)}
                      />
                      {_item.name}
                    </div>
                  ))}
                  <div className={[styles.label, mainStyles['mt-4']].join(' ')}>セキュリティ権限設定</div>
                  <div className={[styles.text, mainStyles['d-flex']].join(' ')}>
                    <Checkbox
                      label="SAML認証"
                      value={registerAuthorityApi.form.hasSsoSupport.value}
                      onChange={registerAuthorityApi.form.hasSsoSupport.toggle}
                    />
                  </div>
                  <div className={[styles.label, mainStyles['mt-4']].join(' ')}>全文検索設定</div>
                  <div className={[styles.text, mainStyles['d-flex']].join(' ')}>
                    <Checkbox
                      label="全文検索"
                      value={registerAuthorityApi.form.hasOcrSupport.value}
                      onChange={registerAuthorityApi.form.hasOcrSupport.toggle}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.headerInfoBox}>
              <div className={styles.infoTextBox}>
                <div className={styles.infoTitle}>取引先コード:</div>
                <div className={styles.infoText} title={customerCd}>{customerCd}</div>
              </div>
              <div className={styles.infoTextBox}>
                <div className={styles.infoTitle}>取引先名称:</div>
                <div className={styles.infoText} title={customerName}>{customerName}</div>
              </div>
              <div className={styles.infoTextBox}>
                <div className={styles.infoTitle}>テナントコード:</div>
                <div
                  className={styles.infoText}
                  title={customerTenantCode}
                >
                  {customerTenantCode}
                </div>
              </div>
            </div>
          </div>
          <div className={[styles.foooter, mainStyles['border-top']].join(' ')}>
            <Button
              size="smaller"
              color="lighterGray"
              onClick={() => navigate(routes.supplierListScreen)}
            >
              キャンセル
            </Button>
            <Button
              size="smaller"
              onClick={registerAuthority}
            >
              保存
            </Button>
          </div>
          <AlertModal
            loading={loading}
            open={alertModal.open}
            text={alertModal.text}
            onConfirm={alertModal.onConfirm}
            onCancel={alertModal.onCancel}
            textCenter
          />
          <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} closeButtonText={closeAlertModal.closeButtonText} />
        </div>
      )}
    />
  );
}

export default Component;
