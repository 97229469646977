import { useLocation, useNavigate } from 'react-router-dom';
import Papa, { ParseResult } from 'papaparse';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import { v1 as uuid } from 'uuid';
import MainFrame from '../../components/MainFrame/mainFrame';
import styles from './csvImportScreenPage.module.css';
import mainStyles from '../main.module.css';
import usePageTitle from '../../hooks/title.hook';
import Breadcrumb from '../../components/Breadcrumb';
import BaseTable from '../../components/BaseTable';
import Button from '../../components/Button/button';
import routes from '../../utils/routes';
import { CloseAlertModal, closeModalInitialState } from '../../components/AlertModal';
import AutoCompleteInput from '../../components/AutoCompleteInput';
import { ApiError } from '../../services/http';
import { useGetDocumentClassificationAndTypeApi } from '../../hooks/api/documentManagement.hook';
import { BaseTag, Tag } from '../../services/http/tag.api';
import { useMessageModal } from '../../hooks/modal.hook';
import MappingSelect from '../../components/MappingSelect';
import { useGetRelatedTagsApi } from '../../hooks/api/tag.hook';
import FileInputButton from '../../components/ButtonFileInput';
import { DocumentClassificationAndType } from '../../services/http/documentManagement.api';
import { DropDownMenuItem } from '../../components/DropdownMenu';
import { DocumentClassificationOption } from '../../services/http/documents.api';
import {
  CsvDocument,
  FileState,
  Form,
  MappingOption,
  PdfFileText,
  SelectedColumn,
  SelectedOption,
} from './csvImportScreenTypes';
import TagFormat from '../../utils/tagFormat';
import Validator from '../../utils/validators';
import { useAuthContext } from '../../store/auth.store';
import { useFolderSearch } from '../../hooks/api/folder.hook';
import ControlCode from '../../utils/controlCode';
import useEffectOnce from '../../hooks/useEffectOnce.hook';
import LoadingOverlay from '../../components/LoadingOverlay';
import { STATUS_MESSAGES } from '../../utils/messages';

/**
 * CSVマッピングの選択肢情報
 */
export const mappingDefaultSelectOptions = [
  { value: 'ignore', type: null, label: '無視する ' },
  { value: 'docName', type: '文字列', label: '文書名' },
  { value: 'pdfFileName', type: '文字列', label: 'PDFファイル名' },
  { value: 'memo', type: '文字列', label: 'メモ' },
  { value: 'barcodePrinting', type: '文字列', label: 'バーコード印字欄' },
];

/**
 * CSVインポートページ
 */
function Component() {
  usePageTitle('文書登録');
  const navigate = useNavigate();
  const { state } = useLocation();
  const { file } = state as FileState;
  const { folderId } = state as { folderId: number | undefined };

  // states
  const [parsedCsvData, setParsedCsvData] = useState<CsvDocument[] | null>(null);
  const [objKeys, setObjKeys] = useState<string[]>([]);
  const [documentsToRegister, setDocumentsToRegister] = useState<CsvDocument[]>([]);
  const [docsToShowInTheTable, setDocsToShowInTheTable] = useState<CsvDocument[]>([]);
  const [changedObject, setChangedObject] = useState<SelectedOption>({ changedKey: '', changedOption: '' });
  const [closeAlertModal, setCloseAlertModal] = useState(closeModalInitialState);
  const [form, setForm] = useState<Form>({});
  const [selectedColumns, setSelectedColumns] = useState<SelectedColumn[]>([]);
  const [pdfSelected, setPdfSelected] = useState<boolean>(false);
  const [pdfColumnSelected, setPdfColumnSelected] = useState<CsvDocument[]>([]);
  const [pdfFiles, setPdfFiles] = useState<PdfFileText[]>([]);
  const [selectedClassification, setSelectedClassification] = useState<DocumentClassificationOption>({} as DocumentClassificationOption);
  const [mappingSelectOptions, setMappingSelectOptions] = useState<MappingOption[]>(mappingDefaultSelectOptions);
  const [documentClassifications, setDocumentClassifications] = useState<DocumentClassificationAndType[]>([]);
  const [tags, setTags] = useState<BaseTag[]>([]);
  const [selectedRow, setSelectedRow] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);

  const { hasFolderPermission } = useAuthContext();

  const openMessageModal = useMessageModal();

  // Apis
  const getDocumentClassificationAndTypeApi = useGetDocumentClassificationAndTypeApi();
  const getDefaultTagsApi = useGetRelatedTagsApi();
  const folderSearch = useFolderSearch();

  const isContract = useMemo(() => selectedClassification.documentClassification?.name?.startsWith('契約書'), [selectedClassification]);

  // Effects
  useEffectOnce(() => {
    setIsLoading(true);
    if (folderId === undefined) {
      navigate(routes.main);
      return;
    }
    folderSearch.request({}).then((res) => {
      const foundFolder = res.find((item) => item.id === folderId);
      if (!foundFolder || !hasFolderPermission(foundFolder, ControlCode.Write)) {
        navigate(routes.main);
      }
      setIsLoading(false);
    });
  });

  // methods
  const hasColumn = useCallback((columnName: string) => selectedColumns.some((selectedColumn) => selectedColumn.valueFormatted === columnName), [selectedColumns]);

  const throwValidationError = (rowNo: number, columnName: string) => {
    const errorMsg = `${rowNo}行目：「${columnName}」が不正です。\nCSVファイルを修正してから再度取込んでください。`;
    throw new Error(errorMsg);
  };

  const validateDocumentTags = (doc: any) => {
    doc.tags.forEach((tag: Tag & { tagColumn: string }) => {
      // eslint-disable-next-line no-restricted-globals
      if (tag.value && tag.format === TagFormat.NUMBER && isNaN(Number(tag.value))) {
        throwValidationError(doc.rowNo, tag.tagColumn);
      }
      if (tag.value && tag.format === TagFormat.DATE && !Validator.csvStringIsValidDate(tag.value)) {
        throwValidationError(doc.rowNo, tag.tagColumn);
      }
    });
  };

  const validateDocuments = (docsToRegister: any) => {
    docsToRegister.forEach((doc: any) => {
      validateDocumentTags(doc);
      if (!Validator.csvStringIsNotEmpty(doc.docName)) {
        throwValidationError(doc.rowNo, '文書名');
      }
      if (isContract) {
        if (!Validator.csvStringIsZeroOrOne(doc.paperFlg)) {
          throwValidationError(doc.rowNo, '書面有無');
        }
        if (!Validator.csvStringIsZeroOrOne(doc.electronicFlg)) {
          throwValidationError(doc.rowNo, '契約種類');
        }
        if (!Validator.csvStringIsZeroWhenFirstIsOne(doc.electronicFlg, doc.paperFlg)) {
          throwValidationError(doc.rowNo, '書面有無');
        }
      }
    });
  };

  const invertElectronicAndPaperFlgValues = (documents: any) => {
    documents.forEach((doc: any) => {
      const document = doc;
      if (doc.paperFlg === '0') {
        document.paperFlg = '1';
      } else {
        document.paperFlg = '0';
      }
      if (doc.electronicFlg === '0') {
        document.electronicFlg = '1';
      } else {
        document.electronicFlg = '0';
      }
    });
  };

  const parseAndFormatDate = useCallback((value: string) => {
    const dateParts = value.split('/');
    if (dateParts.length >= 3) {
      return `${dateParts[0]}/${dateParts[1].padStart(2, '0')}/${dateParts[2].padStart(2, '0')}`;
    }
    return value;
  }, []);

  const normalizeTagDate = useCallback((documents: any[]) => documents.map((doc: any) => {
    doc.tags.map((tag: Tag) => {
      if (tag.format === TagFormat.DATE) {
        // eslint-disable-next-line no-param-reassign
        tag.value = parseAndFormatDate(tag.value);
      }
      return tag;
    });
    return doc;
  }), [parseAndFormatDate]);

  const formatDocsAndGoToNextPage = () => {
    let docsToRegister = documentsToRegister.map((doc) => {
      const registerColumns: any[] = [];
      const registerTags: CsvDocument[] = [];
      selectedColumns.forEach((selectedColumn) => {
        if (selectedColumn.valueFormatted !== 'ignore' && selectedColumn.valueFormatted !== 'pdfFileName'
          && selectedColumn.valueFormatted !== 'electronicFlg' && selectedColumn.valueFormatted !== 'paperFlg'
          && selectedColumn.valueFormatted !== 'memo' && selectedColumn.valueFormatted !== 'docName'
          && selectedColumn.valueFormatted !== 'barcodePrinting') {
          const registerTag = { [selectedColumn.valueFormatted]: doc[selectedColumn.columnName] };
          registerTags.push(registerTag);
        } else {
          const registerObject = {
            [selectedColumn.valueFormatted]: doc[selectedColumn.columnName],
          };
          registerColumns.push(registerObject);
          registerColumns.push({ rowNo: doc.No });
        }
      });
      const tagsWithValues: (Tag & { tagColumn: string })[] = [];
      tags.forEach((tag) => {
        const tagValue = registerTags.find((registerTag) => registerTag[tag.tagLabel]);
        const tagColumn = selectedColumns.find((selectedColumn) => selectedColumn.valueFormatted === tag.tagLabel);
        const newTag: Tag & { tagColumn: string } = {
          tagLabel: tag.tagLabel,
          format: tag.format,
          required: tag.require,
          value: tagValue ? tagValue[tag.tagLabel] as string : '',
          tagColumn: tagColumn ? tagColumn.columnName : '',
        };
        tagsWithValues.push(newTag);
      });

      registerColumns.push({ tags: tagsWithValues });

      return registerColumns.reduce((acc: CsvDocument, cur: CsvDocument) => {
        const key = Object.keys(cur)[0];
        // eslint-disable-next-line no-param-reassign
        acc[key as string] = cur[key] as string | number;
        return acc;
      }, {});
    });

    const pdfToTransfer: PdfFileText[] = [];

    docsToRegister.forEach((doc) => {
      const pdfFile = pdfFiles.find((pdf) => pdf.pdfFileName === doc.pdfFileName);
      if (!pdfFile) {
        // eslint-disable-next-line no-param-reassign
        delete doc.pdfFileName;
      } else {
        pdfToTransfer.push(pdfFile);
      }
    });

    setForm({ ...form, docsToRegister });

    docsToRegister = normalizeTagDate(docsToRegister);

    validateDocuments(docsToRegister);

    if (isContract) {
      invertElectronicAndPaperFlgValues(docsToRegister);
    }

    navigate(routes.docRegisterScreen, { state: { folderId, form: { ...form, docsToRegister }, pdfList: pdfToTransfer } });
  };

  const verifyIfRequiredAttributesExist = () => {
    if (!hasColumn('docName') || !hasColumn('pdfFileName')) {
      throw new Error('文書名、PDFファイル名 のいずれかが選択されていません。');
    }

    if (isContract) {
      if (!hasColumn('electronicFlg') || !hasColumn('paperFlg')) {
        throw new Error('文書名、PDFファイル名、契約種類, 書面有無 のいずれかが選択されていません。');
      }
    }
  };

  const onNext = () => {
    try {
      verifyIfRequiredAttributesExist();
      formatDocsAndGoToNextPage();
    } catch (e) {
      openMessageModal((e as Error).message);
    }
  };

  const onRowNumberSelected = useCallback((targetRow: string) => {
    setSelectedRow(Number(targetRow));
    const TargetRowMinus1 = Number(targetRow) - 1;
    const sliced100docs = parsedCsvData?.slice(TargetRowMinus1, TargetRowMinus1 + 100) ?? [];
    const restOfDocs = parsedCsvData?.slice(TargetRowMinus1) ?? [];
    setDocsToShowInTheTable(sliced100docs);
    setDocumentsToRegister(restOfDocs);
  }, [parsedCsvData]);

  const makeRowSelectOptions = useCallback((objLength: number) => {
    const options = [];
    for (let i = 0; i < objLength; i += 1) {
      options.push({
        value: i + 1,
        label: i + 1,
      });
    }
    return options;
  }, []);

  const getDynamicColumn = useCallback((array: object[]) => Object.keys(array[0]).map(((key) => {
    const columnWidth = key === Object.keys(array[0])[0] ? 80 : 150;
    return {
      field: key, headerName: key, cellClass: 'textFormat', width: columnWidth,
    };
  })), []);

  const generateColumns = useCallback((data: object[]) => {
    const lastColumn = { headerName: '', field: '', flex: 1 };
    return [...getDynamicColumn(data), lastColumn];
  }, [getDynamicColumn]);

  const clearColumns = useCallback((data: object[]) => data
    .map((obj) => Object.entries(obj)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value.trim() }), {})), []);

  const parseFile = useCallback((fileToParse: string, agGridParams: GridReadyEvent) => {
    Papa.parse(fileToParse, {
      header: true,
      skipEmptyLines: 'greedy',
      complete: (results: ParseResult<object>) => {
        const { data } = results;
        const clearColumnsData = clearColumns(data);
        const dataWithNo = clearColumnsData.map((obj, index) => ({ No: index + 1, ...obj }));
        const allColumns = generateColumns(dataWithNo);
        agGridParams.api.setColumnDefs(allColumns);
        setParsedCsvData(dataWithNo);
        setObjKeys(Object.keys(data[0]));
        setDocumentsToRegister(dataWithNo);
        setDocsToShowInTheTable(dataWithNo.slice(0, 100));
      },
    });
  }, [clearColumns, generateColumns]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    parseFile(file, params);
  }, [file, parseFile]);

  const onPdfSelected = useCallback((value: string, column: string) => {
    const pdfs = documentsToRegister.map((doc) => ({
      [value]: doc[column],
    }));
    setPdfColumnSelected(pdfs);
  }, [documentsToRegister]);

  const checkAndSetPdfFiles = async (files: File[]) => {
    setIsLoading(true);
    const pdfFilesToAdd:PdfFileText[] = [];
    const csvNames:Record<string | number, boolean> = {};
    const addedNames:Record<string | number, boolean> = {};

    pdfColumnSelected.forEach((pdf) => { csvNames[pdf.pdfFileName] = true; });
    files.forEach((folderFile) => {
      const pdfExists = csvNames[folderFile.name];
      if (pdfExists) {
        if (addedNames[folderFile.name]) return;
        pdfFilesToAdd.push({ pdfFileName: folderFile.name, pdfFile: folderFile });
        addedNames[folderFile.name] = true;
      }
    });

    setPdfFiles(pdfFilesToAdd);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [pdfFiles]);

  const onFolderUpload = (files: File[]) => {
    const filesArray = Array.from(files);
    checkAndSetPdfFiles(filesArray);
  };

  const getDefaultSelectOptions = (selected: DocumentClassificationOption) => {
    const contractTypeIsTypeContract = selected.documentClassification?.name?.startsWith('契約書');

    if (contractTypeIsTypeContract) {
      return [...mappingDefaultSelectOptions,
        { value: 'electronicFlg', type: '数値', label: '契約種類' },
        { value: 'paperFlg', type: '数値', label: '書面有無' },
      ];
    }
    return mappingDefaultSelectOptions;
  };

  const onDocumentTypeSelected = useCallback((selected: DocumentClassificationOption) => {
    const defaultSelectOptions = getDefaultSelectOptions(selected);

    setSelectedClassification(selected);
    setForm({ ...form, documentClassificationOption: selected });
    const fetchTags = async () => {
      if (selected.documentClassification.id === null) return;
      try {
        const defaultTags = await getDefaultTagsApi.request(selected.documentClassification.id || 0, selected.type.documentTypeId || 0);
        const tagsOptions: MappingOption[] = [
          ...defaultTags.map((item: BaseTag): MappingOption => ({
            value: item.tagLabel, type: TagFormat.getType(item.format), label: item.tagLabel, require: item.require,
          })),
        ];

        const tagsOptionsWithRequireMark = tagsOptions.map((tag) => {
          if (tag.require) {
            return { ...tag, label: `${tag.label}  *` };
          }
          return tag;
        });

        setMappingSelectOptions([...defaultSelectOptions, ...tagsOptionsWithRequireMark]);
        setTags(defaultTags);
      } catch (e) {
        openMessageModal((e as ApiError)?.message);
      }
    };

    fetchTags();
  }, [form, getDefaultTagsApi, openMessageModal]);

  const onOptionSelected = useCallback((value: string, columnName: string, previousSelectedValue: string) => {
    const valueFormatted = JSON.parse(value).value;

    const previousSelectedValueFormatted = previousSelectedValue === 'ignore' ? previousSelectedValue : JSON.parse(previousSelectedValue).value;

    if (valueFormatted === 'pdfFileName') onPdfSelected(valueFormatted, columnName);

    if (valueFormatted === 'ignore') {
      const newSelectedColumns = selectedColumns.filter((selectedColumn) => selectedColumn.valueFormatted !== previousSelectedValueFormatted);
      setSelectedColumns(newSelectedColumns);
      return;
    }
    const newItem = { valueFormatted, columnName };
    setSelectedColumns((prev) => {
      const prevFilteredByColumnName = prev.filter((item) => item.columnName !== columnName);
      const index = prevFilteredByColumnName.findIndex((column) => column.valueFormatted === valueFormatted);
      if (index === -1) {
        return [...prevFilteredByColumnName, newItem];
      }
      prevFilteredByColumnName[index] = newItem;
      return prevFilteredByColumnName;
    });

    setChangedObject({ changedOption: value, changedKey: columnName });
  }, [onPdfSelected, selectedColumns]);

  // Effects
  useEffect(() => {
    try {
      getDocumentClassificationAndTypeApi.request().then(setDocumentClassifications);
    } catch (e) {
      openMessageModal((e as ApiError)?.message);
    }
  }, []);

  useEffect(() => {
    const checkPdfSelected = selectedColumns.some((selectedColumn) => selectedColumn.valueFormatted === 'pdfFileName');
    setPdfSelected(checkPdfSelected);
  }, [selectedColumns]);

  // Memos
  const documentTypeOptions = useMemo(() => {
    const result: DropDownMenuItem<DocumentClassificationOption>[] = [];
    documentClassifications.forEach((documentClassification) => {
      documentClassification.types.forEach((type) => {
        result.push({
          text: `${documentClassification.name}: ${type.documentTypeName}`,
          value: { documentClassification, type },
        });
      });
    });
    return result;
  }, [documentClassifications]);

  const rowSelectOptions = useMemo(() => {
    if (parsedCsvData == null) return null;

    const options = makeRowSelectOptions(parsedCsvData.length);
    return options.map((option) => (
      <option key={uuid()} value={option.value}>{option.label}</option>
    ));
  }, [makeRowSelectOptions, parsedCsvData]);

  // Const
  const columnWidth = 150;
  const tableMinWidth = `${objKeys.length * 150 + 150}px`;

  return (
    <MainFrame body={(
      <div className={styles.mainFrame}>
        <Breadcrumb crumbs={[{ label: document.title }]} />
        <div className={[styles.mainFrameHeader, mainStyles['pl-3']].join(' ')}>
          管理情報取り込みマッピング設定
        </div>
        <div className={styles.bodyContentTop}>
          <div className={styles.topSection}>
            <div className={styles.text}>
              {' 取り込み開始行 '}
              <select value={selectedRow} className={[mainStyles.select, mainStyles['mx-10px']].join(' ')} style={{ width: '90px' }} onChange={(e) => onRowNumberSelected(e.target.value)}>
                {rowSelectOptions}
              </select>
              {' 行目 '}
            </div>
            <Button
              size="smaller"
              color="lighterGray"
              onClick={() => {
                const downloadTag = document.createElement('a');
                downloadTag.setAttribute('download', '一括登録サンプル');
                downloadTag.href = process.env.REACT_APP_HELP_PAGE_USER_GUIDE as string;
                downloadTag.click();
              }}
            >
              ヘルプ
            </Button>
          </div>
          <div className={[styles.text, styles.ml70px, mainStyles['mt-15px']].join(' ')}>
            取り込みデータ
            {'　'}
            （最初の100行）
          </div>
        </div>
        <div className={styles.mainFrameBody}>
          <div className={styles.bodyContent}>

            <div className={styles.bodyContentMain}>
              <div className={styles.bodyContentMainTable}>
                <div className={mainStyles['mt-15px']} style={{ height: '100%' }}>
                  <BaseTable
                    noRowsText={parsedCsvData == null ? STATUS_MESSAGES.LOADING_DATA : STATUS_MESSAGES.NO_DATA}
                    formName="userRegisterFromCsvTable"
                    rowData={docsToShowInTheTable}
                    onGridReady={onGridReady}
                    minWidthProp={tableMinWidth}
                  />
                </div>
              </div>
              <div className={styles.bodyContentMainBottom}>
                <div className={[styles.text, styles.ml70px, mainStyles['mt-15px'], styles.textBold].join(' ')}>
                  マッピング設定
                </div>
                <div className={[styles.text, styles.ml70px, mainStyles['mt-15px'], mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                  <div className={styles.w100px}>文書種類</div>
                  <AutoCompleteInput
                    className={styles.select}
                    style={{ width: '185px' }}
                    options={documentTypeOptions}
                    onSelect={onDocumentTypeSelected}
                    menuStyle={{ maxHeight: '18vh' }}
                  />
                </div>
                <div className={[styles.text, styles.ml70px, mainStyles['mt-10'], mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                  <div className={styles.w100px}>初期値設定</div>
                  <div className={mainStyles['text-danger']}>
                    ※初期値の設定ですので、次の画面で個別設定することも可能です。
                  </div>
                </div>
                <div className={[mainStyles['d-flex'], mainStyles['mt-20px'], styles.mr80px].join(' ')}>
                  <div className={[styles.text, styles.w70px, mainStyles['d-flex'], mainStyles['align-items-center'], mainStyles['justify-content-end']].join(' ')}>
                    <span className={[mainStyles['mr-3'], styles.w70px, mainStyles['d-flex'], mainStyles['justify-content-end']].join(' ')}>
                      CSV
                    </span>
                  </div>
                  <div className={[mainStyles['flex-1'], mainStyles['d-flex'], mainStyles['align-items-center']].join(' ')}>
                    <div className={[styles.divBox, styles.borderLeft].join(' ')} style={{ minWidth: '80px', maxWidth: '80px' }}>No</div>
                    {objKeys.map((key) => (
                      <div key={key} className={[styles.divBox].join(' ')} style={{ minWidth: `${columnWidth}px`, maxWidth: `${columnWidth}px` }}>{key}</div>
                    ))}
                  </div>
                </div>
                <div className={mainStyles['d-flex']}>
                  <div className={[styles.text, styles.w70px, mainStyles['d-flex'], mainStyles['justify-content-end']].join(' ')}>
                    <span className={[styles.downArrow, mainStyles['mr-4'], styles.w70px].join(' ')}>
                      ↓
                    </span>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div>
                    <div style={{
                      display: 'flex', fontSize: '14px', color: '#555555',
                    }}
                    >
                      <div style={{ marginRight: '14px', minWidth: '56px' }}>
                        登録項目
                      </div>
                      <div className={[styles.divBox, styles.borderLeft].join(' ')} style={{ minWidth: '80px', maxWidth: '80px', backgroundColor: '#f2f2f2' }} />
                    </div>
                    <div style={{
                      display: 'flex', fontSize: '14px', color: '#555555', marginLeft: '27px',
                    }}
                    >
                      <div style={{ marginRight: '15px', display: 'flex', justifyContent: 'end' }}>
                        書式
                      </div>
                      <div className={[styles.divBox, styles.borderLeft].join(' ')} style={{ minWidth: '80px', maxWidth: '80px', borderTop: '0' }}>No</div>
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    {objKeys.map((key) => (
                      <MappingSelect
                        key={key}
                        objKey={key}
                        showDocType
                        columnWidth={columnWidth}
                        onOptionSelected={onOptionSelected}
                        optionWasChanged={changedObject}
                        selectOptions={mappingSelectOptions}
                      />
                    ))}
                  </div>
                </div>
                <div className={[styles.documentTypeText].join(' ')}>
                  <div>
                    契約種類
                    <span> 0：電子契約 </span>
                    <span>1：書面契約</span>

                  </div>
                  <div>
                    書面有無
                    <span> 0：有り</span>
                    <span>1：無し</span>
                  </div>
                </div>
                <div className={[styles.text, styles.ml70px, mainStyles['mt-2']].join(' ')} style={{ height: '70px' }}>
                  {pdfSelected && (
                    <>
                      <FileInputButton
                        accept=".."
                        isFolderUpload
                        multiple
                        color="lightGray"
                        fileButtonText="PDFアップロード"
                        onSelect={(e) => onFolderUpload(e as any)}
                      />
                      <div className={[mainStyles['text-danger'], mainStyles['mt-1']].join(' ')}>
                        ※アップロードするファイルは、PDFファイル名例で指定したファイル名と一致している必要があります。
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <CloseAlertModal open={closeAlertModal.open} text={closeAlertModal.text} onCancel={closeAlertModal.onCancel} />
          </div>

        </div>
        <div className={styles.bodyFooter}>
          <Button color="lightGray" size="smaller" onClick={() => navigate(routes.main)}>キャンセル</Button>
          <Button className={`${mainStyles['ml-1']}`} size="smaller" onClick={onNext}>
            次へ
          </Button>
        </div>
        {isLoading && <LoadingOverlay />}
      </div>
    )}
    />
  );
}

export default Component;
